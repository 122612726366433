import { Routes, Route } from "react-router-dom";

import PrivateRoute from "@routes/PrivateRoute";
import NotFound from "@routes/NotFound";

import Analytics from "@pages/Analytics";
import Calendar from "@pages/Calendar";
import Contact from "@pages/Contact";
import Help from "@pages/Help";
import History from "@pages/History";
import Home from "@pages/Home";
import NewPromotion from "@pages/NewPromotion";
import Reports from "@pages/Reports";
import Privacy from "@pages/Privacy";
import Promotion from "@pages/Promotion";
import PromotionEdit from "@pages/PromotionEdit";
import Promotions from "@pages/Promotions";
import Password from "@pages/Settings";
import Signin from "@pages/Signin";
import Signup from "@pages/Signup";
import PasswordReset from "@pages/PasswordReset";
import Terms from "@pages/Terms";
import Images from "@pages/Images";
import Menus from "@pages/Menus";
import Amenities from "@pages/Amenities";
import Schedule from "@pages/Schedule";
import Verification from "@pages/Verification";
import LogoUpload from "@components/pages/Logo";

import View from "@templates/Main";

const AnimatedRoutes = () => {
	return (
		<Routes>
			<Route
				path="/history"
				element={
					<PrivateRoute>
						<View component={<History />} />
					</PrivateRoute>
				}
			/>
			<Route
				path="/calendar"
				element={
					<PrivateRoute>
						<View component={<Calendar />} />
					</PrivateRoute>
				}
			/>
			<Route
				path="/reports"
				element={
					<PrivateRoute>
						<View component={<Reports />} />
					</PrivateRoute>
				}
			/>
			<Route
				path="/password"
				element={
					<PrivateRoute>
						<View component={<Password />} />
					</PrivateRoute>
				}
			/>
			<Route
				path="/amenities"
				element={
					<PrivateRoute>
						<View component={<Amenities />} />
					</PrivateRoute>
				}
			/>
			<Route
				path="/images"
				element={
					<PrivateRoute>
						<View component={<Images />} />
					</PrivateRoute>
				}
			/>
			<Route
				path="/menus"
				element={
					<PrivateRoute>
						<View component={<Menus />} />
					</PrivateRoute>
				}
			/>
			<Route
				path="/verification"
				element={
					<PrivateRoute>
						<View component={<Verification />} />
					</PrivateRoute>
				}
			/>
			<Route
				path="/help"
				element={
					<PrivateRoute>
						<View component={<Help />} />
					</PrivateRoute>
				}
			/>

			<Route
				path="/privacy"
				element={
					<PrivateRoute>
						<View component={<Privacy />} />
					</PrivateRoute>
				}
			/>
			<Route
				path="/schedule"
				element={
					<PrivateRoute>
						<View component={<Schedule />} />
					</PrivateRoute>
				}
			/>
			<Route
				path="/logo"
				element={
					<PrivateRoute>
						<View component={<LogoUpload />} />
					</PrivateRoute>
				}
			/>
			<Route
				path="/analytics"
				element={
					<PrivateRoute>
						<View component={<Analytics />} />
					</PrivateRoute>
				}
			/>
			<Route
				exact
				path="/promotions"
				element={
					<PrivateRoute>
						<View component={<Promotions />} />
					</PrivateRoute>
				}
			/>
			<Route
				path="/promotions/:id/edit"
				element={
					<PrivateRoute>
						<View component={<PromotionEdit />} />
					</PrivateRoute>
				}
			/>
			<Route
				path="/promotions/new"
				element={
					<PrivateRoute>
						<View component={<NewPromotion />} />
					</PrivateRoute>
				}
			/>
			<Route
				exact
				path="/promotions/:id"
				element={
					<PrivateRoute>
						<View component={<Promotion />} />
					</PrivateRoute>
				}
			/>
			<Route
				path="/contact"
				element={
					<PrivateRoute>
						<View component={<Contact />} />
					</PrivateRoute>
				}
			/>
			<Route
				exact
				path="/"
				element={
					<PrivateRoute>
						<View component={<Home />} />
					</PrivateRoute>
				}
			/>
			<Route
				path="/terms"
				element={
					<PrivateRoute>
						<View component={<Terms />} />
					</PrivateRoute>
				}
			/>

			<Route path="/signin" element={<View component={<Signin />} />} />
			<Route
				path="/password-reset"
				element={<View component={<PasswordReset />} />}
			/>
			<Route
				exact
				path="/signup"
				element={<View component={<Signup step={1} />} />}
			/>
			<Route
				exact
				path="/signup/restaurant"
				element={<View component={<Signup step={2} />} />}
			/>
			<Route
				exact
				path="/signup/address"
				element={<View component={<Signup step={3} />} />}
			/>
			<Route
				exact
				path="/signup/amenities"
				element={<View component={<Signup step={4} />} />}
			/>
			<Route
				exact
				path="/signup/password"
				element={<View component={<Signup step={5} />} />}
			/>
			<Route
				exact
				path="/signup/account-create"
				element={<View component={<Signup step={6} />} />}
			/>
			<Route
				path="*"
				element={<View component={<NotFound />} notFound={true} />}
			/>
		</Routes>
	);
};

export default AnimatedRoutes;
