const googleScheduleParser = (schedule) => {
	const missingDayValidation = [];
	const parsedSchedule = schedule.map(({ close, open }) => {
		const parsedOpenTime = open.hours * 3600 + open.minutes * 60;
		const parsedCloseTime = close.hours * 3600 + close.minutes * 60;
		return { open: parsedOpenTime, close: parsedCloseTime, day: open.day };
	});

	let dayCount = 0;

	for (let i = 0; i < 7; i += 1) {
		if (parsedSchedule[i - dayCount].day === i) {
			missingDayValidation.push({
				open: parsedSchedule[i - dayCount].open,
				close: parsedSchedule[i - dayCount].close,
				status: true,
			});
		} else {
			dayCount += 1;

			missingDayValidation.push({
				open: 36000,
				close: 75600,
				status: false,
			});
		}
	}

	return missingDayValidation;
};

export default googleScheduleParser;
