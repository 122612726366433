import { lazy } from "react";

import withFallback from "@HOC/withFallback";

import terms from "@constants/terms";

const LegalTemplate = withFallback(
	lazy(() => {
		return import("@templates/Legal");
	}),
);

const Privacy = () => {
	return <LegalTemplate>{terms}</LegalTemplate>;
};

export default Privacy;
