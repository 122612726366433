import { lazy, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "@hooks/useAuth";

import withFallback from "@HOC/withFallback";

const SignupForm = withFallback(
	lazy(() => {
		return import("@forms/SignupForm");
	}),
);
const AddressForm = withFallback(
	lazy(() => {
		return import("@forms/AddressForm");
	}),
);
const PasswordForm = withFallback(
	lazy(() => {
		return import("@forms/PasswordForm");
	}),
);
const RestaurantForm = withFallback(
	lazy(() => {
		return import("@forms/RestaurantInfoForm");
	}),
);

const Header = withFallback(
	lazy(() => {
		return import("@organisms/EntryHeader");
	}),
);
const Amenities = withFallback(
	lazy(() => {
		return import("@components/ui/organisms/Amenities");
	}),
);
const AccountCreate = withFallback(
	lazy(() => {
		return import("@organisms/AccountCreate");
	}),
);

const Signup = ({ step }) => {
	const { REACT_APP_AUTH_KEY } = process.env;
	const navigate = useNavigate();
	const auth = useAuth();
	const location = useLocation();

	const { from } = location.state || { from: { pathname: "/" } };

	useEffect(() => {
		if (auth.user) {
			navigate(from, { replace: true });
		}
	}, [auth.user, navigate, from, REACT_APP_AUTH_KEY]);
	const withHeader = (Component) => {
		const WrappedComponent = (props) => {
			return (
				<>
					<Header type={"signup"} />
					<Component {...props} />
				</>
			);
		};
		return WrappedComponent;
	};
	const SignupStep = withHeader(SignupForm);
	const RestaurantInfoStep = withHeader(RestaurantForm);
	const AddressStep = withHeader(AddressForm);
	const AmenitiesStep = withHeader(Amenities);
	const PasswordStep = withHeader(PasswordForm);
	const AccountCreateStep = withHeader(AccountCreate);

	switch (step) {
		case 1:
			return <SignupStep />;
		case 2:
			return <RestaurantInfoStep />;
		case 3:
			return <AddressStep />;
		case 4:
			return <AmenitiesStep />;
		case 5:
			return <PasswordStep />;
		case 6:
			return <AccountCreateStep />;
		default:
			return <SignupStep />;
	}
};

export default Signup;
