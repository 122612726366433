import { useState, useEffect, lazy } from "react";
import { useParams } from "react-router-dom";

import useLocalization from "@hooks/useLocalization";
import usePromotions from "@hooks/usePromotions";

import withFallback from "@HOC/withFallback";

const PromotionInfo = withFallback(
	lazy(() => {
		return import("@organisms/PromotionInfo");
	}),
);

const Promotion = () => {
	const { id } = useParams();
	const [promotions, { filterPromotion }, isLoaded] = usePromotions();
	const [{ promotion: localization }] = useLocalization();

	const [currentPromotion, setCurrentPromotion] = useState(
		filterPromotion(id) || null,
	);

	useEffect(() => {
		if (isLoaded) {
			setCurrentPromotion(filterPromotion(id));
		}
	}, [filterPromotion, id, isLoaded]);

	return promotions && currentPromotion ? (
		<div className="h-auto max-w-lg p-4 mx-auto my-4 overflow-hidden bg-white border border-gray-300 rounded-md sm:px-6 lg:px-8">
			<PromotionInfo promotion={currentPromotion} />
		</div>
	) : (
		<div>{localization.noMatch}</div>
	);
};

export default Promotion;
