import { lazy } from "react";

import withFallback from "@HOC/withFallback";

const ContactCard = withFallback(
	lazy(() => {
		return import("@organisms/ContactCard");
	}),
);

const Contact = () => {
	return (
		<div className="px-4 py-8 mx-auto bg-gray-100 max-w-7xl sm:py-12 sm:px-6 lg:px-8">
			<ContactCard />
		</div>
	);
};

export default Contact;
