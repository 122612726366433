import RequiredIcon from "@atoms/RequiredIcon";

const Label = ({ htmlFor, className, children, required }) => {
	return (
		<div className="flex">
			<label
				htmlFor={htmlFor}
				className={`${
					className ? className : ""
				} block text-sm text-gray-700`}
			>
				{children}
			</label>
			<RequiredIcon required={required} />
		</div>
	);
};

export default Label;
