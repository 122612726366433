const parseElement = (element) => {
	const parsed = element
		? "long_name" in element
			? element.long_name
			: "short_name" in element
			? element.short_name
			: ""
		: "";
	return parsed;
};

export const findAndFilter = (address, type) => {
	return parseElement(
		address.find(({ types }) => {
			return types.includes(type);
		}),
	);
};

export const parseAddress = (address) => {
	return {
		streetNumber: findAndFilter(address, "street_number"),
		subpremise: findAndFilter(address, "subpremise"),
		street: findAndFilter(address, "route"),
		neighborhood: findAndFilter(address, "neighborhood"),
		sublocality: findAndFilter(address, "sublocality"),
		county: findAndFilter(address, "administrative_area_level_2"),
		city: findAndFilter(address, "locality"),
		state: findAndFilter(address, "administrative_area_level_1"),
		country: findAndFilter(address, "country"),
		postalCode: findAndFilter(address, "postal_code"),
	};
};

export default parseElement;
