import { forwardRef } from "react";
import { Dialog } from "@headlessui/react";

const { Title } = Dialog;

const ModalBody = forwardRef(
	(
		{
			icon,
			title,
			content,
			setIsOpen,
			closeButton,
			closeAction,
			activeButton,
			activeAction,
			disableActionButton = false,
			onlyActive = false,
		},
		ref,
	) => {
		return (
			<div className="inline-block w-full mx-8 md:mx-0 align-bottom bg-white rounded-lg px-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg md:max-w-3xl sm:w-full pt-5 pb-8 sm:p-8">
				<div>
					<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
						{icon}
					</div>
					<div className="mt-3 text-center sm:mt-5">
						<Title
							as="h3"
							className="text-lg leading-6 font-medium text-gray-900"
						>
							{title}
						</Title>
						<div className="mt-2">{content}</div>
					</div>
				</div>
				<div
					className={`${
						onlyActive
							? "text-center"
							: "sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
					} `}
				>
					<button
						disabled={disableActionButton}
						ref={onlyActive ? ref : null}
						type="button"
						className={`${
							onlyActive ? "w-1/2" : " sm:col-start-2 w-full"
						} inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 disabled:cursor-not-allowed disabled:bg-gray-400 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:text-sm`}
						onClick={() => {
							setIsOpen(false);
							activeAction && activeAction();
						}}
					>
						{activeButton ? activeButton : "Activate"}
					</button>
					{onlyActive ? null : (
						<button
							type="button"
							className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:col-start-1 sm:text-sm"
							onClick={() => {
								setIsOpen(false);
								closeAction && closeAction();
							}}
							ref={ref}
						>
							{closeButton ? closeButton : "Close"}
						</button>
					)}
				</div>
			</div>
		);
	},
);

ModalBody.displayName = "ModalBody";

export default ModalBody;
