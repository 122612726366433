import { useContext, useEffect } from "react";

import { Context as PromotionContext } from "@context/promotionContext";

import { useAuth } from "@hooks/useAuth";

const usePromotions = () => {
	const auth = useAuth();
	const {
		state: { data: promotions, isLoaded },
		getPromotions,
		createPromotion,
		togglePromotion,
		deletePromotion,
		updatePromotion,
		resetPromotionContext,
	} = useContext(PromotionContext);

	useEffect(() => {
		if (!isLoaded && auth) {
			getPromotions(auth.user.uid);
		}
	});
	useEffect(() => {
		console.log("promotions:", promotions);
	}, [promotions]);

	const filterPromotion = (index) => {
		const promo = promotions.find((promotion) => {
			return parseInt(promotion.index) === parseInt(index);
		});
		return promo ? promo : null;
	};

	return [
		promotions,
		{
			getPromotions,
			createPromotion,
			togglePromotion,
			deletePromotion,
			updatePromotion,
			resetPromotionContext,
			filterPromotion,
		},
		isLoaded,
	];
};

export default usePromotions;
