import { initializeApp } from "firebase/app";
import {
	getFirestore,
	collection,
	addDoc,
	getDoc,
	getDocs,
	arrayUnion,
	arrayRemove,
	updateDoc,
	query,
	deleteDoc,
	doc,
	where,
	GeoPoint,
	setDoc,
	Timestamp,
} from "firebase/firestore";
import {
	getAuth,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	signOut,
	fetchSignInMethodsForEmail,
	sendPasswordResetEmail,
	confirmPasswordReset,
	onAuthStateChanged,
	updateProfile,
	reauthenticateWithCredential,
	EmailAuthProvider,
	updatePassword,
	setPersistence,
	browserLocalPersistence,
} from "firebase/auth";
import {
	getStorage,
	ref,
	uploadBytes,
	_TaskState,
	getDownloadURL,
	uploadBytesResumable,
} from "firebase/storage";

const {
	REACT_APP_API_KEY,
	REACT_APP_PROJECT_ID,
	REACT_APP_MESSAGING_SENDER_ID,
	REACT_APP_APP_ID,
	REACT_APP_MEASUREMENT_ID,
} = process.env;

const config = {
	apiKey: REACT_APP_API_KEY,
	authDomain: `${REACT_APP_PROJECT_ID}.firebaseapp.com`,
	databaseURL: `https://${REACT_APP_PROJECT_ID}.firebaseio.com`,
	projectId: REACT_APP_PROJECT_ID,
	storageBucket: `${REACT_APP_PROJECT_ID}.appspot.com`,
	messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
	appId: REACT_APP_APP_ID,
	measurementId: `G-${REACT_APP_MEASUREMENT_ID}`,
};

const firebaseApp = initializeApp(config);

const database = getFirestore();

const storage = getStorage();

const firebaseAuth = getAuth();

export {
	_TaskState,
	addDoc,
	arrayRemove,
	arrayUnion,
	browserLocalPersistence,
	collection,
	createUserWithEmailAndPassword,
	confirmPasswordReset,
	database,
	deleteDoc,
	doc,
	EmailAuthProvider,
	firebaseAuth,
	fetchSignInMethodsForEmail,
	GeoPoint,
	getDoc,
	getDocs,
	getDownloadURL,
	getAuth,
	onAuthStateChanged,
	query,
	reauthenticateWithCredential,
	ref,
	sendPasswordResetEmail,
	setDoc,
	signInWithEmailAndPassword,
	signOut,
	setPersistence,
	storage,
	Timestamp,
	updateDoc,
	updatePassword,
	updateProfile,
	uploadBytes,
	uploadBytesResumable,
	where,
};
export default firebaseApp;
