import { useNavigate } from "react-router-dom";
import { MenuAlt1Icon, ChevronLeftIcon } from "@heroicons/react/outline";

import useLocalization from "@hooks/useLocalization";

import Button from "@atoms/Button";
import ScreenReader from "@atoms/ScreenReader";
import ProfileMenu from "@organisms/ProfileMenu";

const NavHeader = ({ toggle, navigateBackwards }) => {
	const navigate = useNavigate();
	const [{ navHeader: localization }] = useLocalization();

	return (
		<div className="relative z-10 flex flex-shrink-0 h-16 bg-white border-b border-gray-200 lg:border-none">
			<button
				onClick={() => {
					return toggle(true);
				}}
				className="px-4 text-gray-400 border-r border-gray-200 cursor-pointer focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 lg:hidden"
			>
				<ScreenReader dictation={localization.sidebarScreenReader} />
				<MenuAlt1Icon className="w-6 h-6" />
			</button>
			<div className="flex justify-between flex-1 px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
				<div className="flex items-center">
					{!navigateBackwards ? (
						<ChevronLeftIcon
							className="w-8 h-8 mr-4 cursor-pointer text-primary-600 hover:text-primary-700"
							onClick={() => {
								return navigate(-1);
							}}
						/>
					) : null}

					<Button
						text={localization.createPromotion}
						className="ml-2"
						screenReader={localization.createPromotion}
						to="/promotions/new"
					/>
				</div>
				<div className="flex items-center ml-4 md:ml-6">
					<button className="p-1 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
						<ScreenReader
							dictation={localization.notificationScreenReader}
						/>
					</button>

					<div className="relative ml-3">
						<ProfileMenu />
					</div>
				</div>
			</div>
		</div>
	);
};

export default NavHeader;
