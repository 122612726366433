import { lazy } from "react";

import withFallback from "@HOC/withFallback";

import Template from "@templates/PaddedContent";
import CardTemplate from "@templates/Card";

const Feed = withFallback(
	lazy(() => {
		return import("@organisms/Feed");
	}),
);

const History = () => {
	return (
		<main className="mt-8">
			<Template>
				<CardTemplate className="p-5">
					<Feed />
				</CardTemplate>
			</Template>
		</main>
	);
};

export default History;
