import axios from "axios";

const geocodeAddress = (address) => {
	const { address_1, city, state, country, zip } = address;

	const encodeUrl = (element) => {
		return element.split(" ").join("+");
	};

	const geoPointRequest = async (url) => {
		try {
			const response = await axios.get(url);
			return response.data.results[0].geometry.location;
		} catch (error) {
			console.log(error);
			throw error;
		}
	};

	const parsedAddress = `${encodeUrl(address_1)}%20${encodeUrl(
		city,
	)}%20${encodeUrl(state)}%20${encodeUrl(country)}%20${zip}`;
	const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${parsedAddress}&key=AIzaSyAty4p2p3lTnqTQczG8NT1LrmtMsYH2VFs`;

	return geoPointRequest(url);
};

export default geocodeAddress;
