import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";

import Button from "@atoms/Button";

const NotFound = ({ route, text }) => {
	const navigate = useNavigate();
	return (
		<div className="bg-white py-16 rounded-md shadow-md flex flex-col max-w-3xl my-16 md:mx-auto mx-4 text-primary-500">
			<div className="flex items-center justify-center">
				<ExclamationCircleIcon className="animate-loader -ml-1 mr-3 h-8 w-8 " />
				<div className="font-bold text-base sm:text-xl md:text-2xl">
					{text}
				</div>
			</div>
			<div className="flex items-center justify-center mt-8">
				<Button
					text="Regresar"
					onClick={() => {
						navigate(route, { replace: true });
					}}
				/>
			</div>
		</div>
	);
};

export default NotFound;
