import { Switch as Toggle } from "@headlessui/react";

import ScreenReader from "@atoms/ScreenReader";

const Switch = ({
	enabled,
	setEnabled,
	customMethod = null,
	disabledColor = "bg-secondary-500",
}) => {
	return (
		<Toggle
			checked={enabled}
			onChange={
				customMethod
					? customMethod
					: () => {
							return setEnabled(!enabled);
					  }
			}
			className={`${
				enabled ? "bg-primary-600" : disabledColor
			} relative inline-flex items-center h-6 rounded-full w-11 focus:outline-none hover:ring-2 hover:ring-offset-2 hover:ring-primary-600 focus:ring-2 focus:ring-primary-500 focus:ring-offset-2`}
		>
			<ScreenReader dictation="Enable Promotion" />
			<span
				className={`${
					enabled ? "translate-x-6" : "translate-x-1"
				} inline-block w-4 h-4 transform transition ease-in-out duration-400 bg-white rounded-full`}
			/>
		</Toggle>
	);
};

export default Switch;
