import { BrowserRouter as Router } from "react-router-dom";

import AnimatedRoutes from "@routes/AnimatedRoutes";

import { ProvideAuth } from "@useAuth";

const Main = () => {
	return (
		<ProvideAuth>
			<Router>
				<AnimatedRoutes />
			</Router>
		</ProvideAuth>
	);
};

export default Main;
