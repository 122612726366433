import createDataContext from "@context/createDataContext";
import localization from "@localization";

const reducer = (state, action) => {
	switch (action.type) {
		case "set_locale":
			return action.payload;
		default:
			return state;
	}
};

const changeLocale = (dispatch) => {
	return (locale) => {
		dispatch({ type: "set_locale", payload: locale });
	};
};

export const { Context, Provider } = createDataContext(
	reducer,
	{ changeLocale },
	localization.es,
);
