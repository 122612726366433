import { Suspense } from "react";

import Fallback from "@atoms/Loading";

const withFallback = (Component) => {
	const WrappedComponent = (props) => {
		return (
			<Suspense fallback={<Fallback />}>
				<Component {...props} />
			</Suspense>
		);
	};
	return WrappedComponent;
};

export default withFallback;
