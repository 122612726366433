const Image = () => {
	return (
		<div className="hidden lg:block relative w-0 flex-1">
			<img
				className="absolute inset-0 h-full w-full object-cover"
				src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixqx=7jR0oiMCYi&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
				alt="decorative"
			/>
		</div>
	);
};

export default Image;
