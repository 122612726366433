import { database, setDoc, doc } from "@utils/firebase";

const createRestaurantAccount = async (uid, data) => {
	try {
		await setDoc(doc(database, "restaurants", uid), data);
		console.log("Document successfully created");
	} catch (error) {
		console.error("Error adding document: ", error);
	}
};

export default createRestaurantAccount;
