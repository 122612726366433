import { lazy } from "react";

import withFallback from "@HOC/withFallback";

const ImageSettings = withFallback(
	lazy(() => {
		return import("@organisms/ImageUpdate");
	}),
);

const Images = () => {
	return (
		<main>
			<ImageSettings />
		</main>
	);
};

export default Images;
