import { useState } from "react";

import { useAuth } from "@useAuth";

import Sidebar from "@organisms/Sidebar";
import NavHeader from "@organisms/NavHeader";

import Image from "@atoms/Image";

const Main = ({ component, notFound = false }) => {
	const auth = useAuth();

	const [isMenuVisible, toggleMenu] = useState(false);

	return auth.user ? (
		<div className="flex h-screen overflow-hidden bg-gray-100">
			<Sidebar show={isMenuVisible} toggle={toggleMenu} />
			<div className="flex-1 overflow-auto focus:outline-none">
				<NavHeader toggle={toggleMenu} />
				{component}
			</div>
		</div>
	) : !notFound ? (
		<div className="flex min-h-screen bg-white">
			<div className="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
				<div className="w-full max-w-sm mx-auto lg:w-96">
					{component}
				</div>
			</div>
			<Image />
		</div>
	) : (
		<div className="flex min-h-screen bg-gray-200">
			<div className="w-full px-4 mx-auto">{component}</div>
		</div>
	);
};

export default Main;
