import { useState, useEffect, useContext, createContext } from "react";

import useRestaurant from "@hooks/useRestaurant";
import usePromotions from "@hooks/usePromotions";

import {
	database,
	GeoPoint,
	signOut,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	fetchSignInMethodsForEmail,
	sendPasswordResetEmail,
	confirmPasswordReset,
	onAuthStateChanged,
	updateProfile,
	firebaseAuth,
	reauthenticateWithCredential,
	EmailAuthProvider,
	updatePassword,
} from "@utils/firebase";
import createRestaurantAccount from "@utils/createRestaurantAccount";

const authContext = createContext();

export const ProvideAuth = ({ children }) => {
	useRestaurant();
	const auth = useProvideAuth();

	useEffect(() => {
		console.log(auth.user);
	}, [auth.user]);

	return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
	return useContext(authContext);
};

const useProvideAuth = () => {
	const [user, setUser] = useState(null);
	const [_, { getRestaurant, resetRestaurantContext }] = useRestaurant();
	const [__, { resetPromotionContext }] = usePromotions();

	const reauthenticateUser = async (email, password) => {
		const credential = EmailAuthProvider.credential(email, password);
		try {
			const res = await reauthenticateWithCredential(
				firebaseAuth.currentUser,
				credential,
			);
			return res;
		} catch (error) {
			throw error.code;
		}
	};

	const signin = async (email, password) => {
		return signInWithEmailAndPassword(firebaseAuth, email, password)
			.then((response) => {
				setUser(response.user);
				return response.user;
			})
			.catch((error) => {
				throw error;
			});
	};

	const signup = async (email, password, data) => {
		return createUserWithEmailAndPassword(firebaseAuth, email, password)
			.then((response) => {
				const {
					address_1,
					address_2,
					amenities,
					categories,
					city,
					coordinates,
					country,
					currentRestaurantId,
					documents,
					email,
					fullSchedule,
					images,
					isUsingGoogleApi,
					logo,
					name,
					phone,
					price,
					rating,
					restaurant_name,
					schedule,
					state,
					surname,
					user_ratings_total,
					website,
					zip,
				} = data;

				updateProfile(firebaseAuth.currentUser, {
					displayName: restaurant_name,
				})
					.then(() => {
						console.log("Username update successful");
						setUser(response.user);
						createRestaurantAccount(response.user.uid, {
							address_1,
							address_2,
							amenities,
							categories,
							city,
							coordinates,
							country,
							currentRestaurantId,
							documents,
							email,
							fullSchedule,
							images,
							isUsingGoogleApi,
							logo,
							name,
							phone,
							price: price.length,
							rating,
							restaurant_name,
							schedule,
							state,
							surname,
							user_ratings_total,
							website,
							zip,
							validated: false,
						});
					})
					.catch((error) => {
						throw error;
					});
				return response.user;
			})
			.catch((error) => {
				throw error;
			});
	};

	const signout = async () => {
		return signOut(firebaseAuth)
			.then(() => {
				setUser(null);
				resetRestaurantContext();
				resetPromotionContext();
			})
			.catch((error) => {
				throw error;
			});
	};

	const updateUsername = async (username) => {
		return updateProfile(firebaseAuth.currentUser, {
			displayName: username,
		})
			.then(() => {
				console.log("Username update successful");
			})
			.catch((error) => {
				throw error;
			});
	};

	const updateUser = async (data, type, navigation) => {
		return updateProfile(firebaseAuth.currentUser, {
			[type]: data,
		})
			.then(() => {
				console.log(`${type} update successful`);
				// getRestaurant(auth.user.uid);
				navigation();
			})
			.catch((error) => {
				throw error;
			});
	};

	const fetchSignInMethodsForMail = async (email) => {
		return fetchSignInMethodsForEmail(firebaseAuth, email)
			.then((response) => {
				const emailInUse = response.length > 0;
				return emailInUse;
			})
			.catch((error) => {
				console.log(error);
				return error;
			});
	};

	const updateDatabaseUserAddress = (id, data) => {
		const { address_1, address_2, city, coordinates, state, country, zip } =
			data;
		const ref = database.collection("restaurants").doc(id);
		return ref
			.update({
				address_1,
				address_2,
				coordinates: new GeoPoint(
					coordinates.latitude,
					coordinates.longitude,
				),
				city,
				state,
				country,
				zip,
			})
			.then(() => {
				console.log("User updated successfully!");
				getRestaurant();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const sendPasswordResetMail = async (email) => {
		return sendPasswordResetEmail(firebaseAuth, email)
			.then(() => {
				return email;
			})
			.catch((error) => {
				throw error;
			});
	};

	const resetPassword = async (newPassword) => {
		try {
			await updatePassword(firebaseAuth.currentUser, newPassword);
			return "Password updated successfully";
		} catch (error) {
			throw error.code;
		}
	};

	const confirmPasswordRes = async (code, password) => {
		return confirmPasswordReset(code, password).then(() => {
			return true;
		});
	};

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
			setUser(user);
		});

		return () => {
			return unsubscribe();
		};
	}, []);

	return {
		user,
		signin,
		signup,
		signout,
		updateUsername,
		updateUser,
		sendPasswordResetMail,
		confirmPasswordRes,
		resetPassword,
		updateDatabaseUserAddress,
		fetchSignInMethodsForMail,
		reauthenticateUser,
	};
};
