import { lazy } from "react";

import withFallback from "@HOC/withFallback";

import privacy from "@constants/privacy";

const LegalTemplate = withFallback(
	lazy(() => {
		return import("@templates/Legal");
	}),
);

const Privacy = () => {
	return <LegalTemplate>{privacy}</LegalTemplate>;
};

export default Privacy;
