import { lazy } from "react";

import withFallback from "@HOC/withFallback";

const LogoUpdate = withFallback(
	lazy(() => {
		return import("@organisms/LogoUpdate");
	}),
);

const Logo = () => {
	return <LogoUpdate />;
};

export default Logo;
