const localization = {
	es: {
		signin: {
			title: "Ingresa a la cuenta de tu restaurante",
			or: "O",
			link: "crea una cuenta",
			email: "Email",
			password: "Contraseña",
			remember: "Recordar cuenta",
			forgot: "¿Olvidé mi contraseña?",
			signin: "Ingresar",
			requiredEmail: "Email es un campo requerido",
			emailPatternValidation: "El email no tiene el formato correcto",
			requiredPassword: "Contraseña es un campo requerido",
			passwordLengthValidation:
				"La contraseña debe ser al menos 8 caracteres",
		},
		signup: {
			title: "Crea una cuenta de restaurante",
			or: "O",
			link: "Ingresa a tu cuenta",
		},
		sidebar: {
			home: "Inicio",
			history: "Historial",
			reservations: "Activaciones",
			promotions: "Promociones",
			calendar: "Calendario",
			analytics: "Analíticos",
			reports: "Reportes",
			settings: "Documentos",
			contact: "Contacto",
			help: "Ayuda",
			privacy: "Privacidad",
			terms: "Términos",
		},
		signInProviders: {
			title: "Ingresar con",
			facebookScreenReader: "Ingresar con Facebook",
			twitterScreenReader: "Ingresar con Twitter",
			gitHubScreenReader: "Ingresar con GitHub",
			continue: "O ingresar con",
		},
		recentActivity: {
			people: "personas",
			reservation: "Reservación",
			promotion: "Promoción",
			status: "Estado",
			date: "Fecha",
		},
		pagination: {
			showing: "Mostrando",
			to: "al",
			of: "de",
			results: "resultados",
			previous: "Anterior",
			next: "Siguiente",
		},
		promotionCards: {
			modalTitle: "Activar promoción",
			modalBody: "Elige el horario en el que estará activa la promoción",
			modalClose: "Cerrar",
			modalActive: "Activar",
			active: "Promociones activas",
			inactive: "Promociones inactivas",
			deleteDictation: "Borrar promoción",
		},
		deactivatePromotionModal: {
			modalTitle: "Desactivar promoción",
			modalBody:
				"Esta acción desactivará la promoción y dejará de ser visible para los clientes.",
			modalClose: "Cerrar",
			modalActive: "Desactivar",
		},
		profileMenu: {
			profile: "Tu perfil",
			changeUser: "Cambiar usuario",
			settings: "Ajustes",
			logout: "Salir",
			name: "Nombre de restaurante",
			menuScreenReader: "Abrir menú para",
		},
		overview: {
			title: "Reservaciones totales",
			viewAll: "Ver todas",
		},
		navHeader: {
			sidebarScreenReader: "Abrir menú",
			createPromotion: "Crear promoción",
			notificationScreenReader: "Ver notificaciones",
		},
		infoSidebar: {
			title: "Información de contacto",
			body: "Si tienes alguna pregunta o sugerencia que no sea urgente, por favor llena este formulario. Si necesitas ayuda inmediata, utiliza el widget de contacto.",
		},
		header: {
			title: "Bienvenido al panel de restaurante",
			address: "Pino 65 Casa 1 Col Coyoacán",
			verified: "Cuenta verificada",
			unverified: "Cuenta no verificada",
			link: "Ir a promociones",
		},
		addressForm: {
			address_1: "Dirección 1",
			address_2: "Dirección 2",
			city: "Ciudad",
			state: "Estado/Provincia",
			country: "País",
			zip: "Código postal",
			buttonScreenReader: "Botón continuar",
			button: "Continuar",
			buttonChange: "Confirmar cambios",
			buttonChangeScreenReader: "Botón confirmar cambios",
		},
		deletePromotionModal: {
			modalTitle: "Borrar promoción",
			modalBody:
				"Esta acción eliminará la promoción. Esta acción no se puede revertir",
			modalActive: "Eliminar",
			modalClose: "Cancelar",
		},
		signupForm: {
			name: "Nombre",
			nameErrorMessage: "Nombre es un campo requerido",
			surname: "Apellido(s)",
			surnameErrorMessage: "Apellido es un campo requerido",
			email: "Cuenta de email",
			emailErrorMessage: "Email es un campo requerido",
			emailPatternValidation: "Email no tiene el formato correcto",
			password: "Contraseña",
			currentPassword: "Contraseña actual",
			reEnterPassword: "Reingresar contraseña",
			newPassword: "Nueva contraseña",
			passwordErrorMessage: "Contraseña es un campo requerido",
			passwordLengthErrorMessage:
				"La contraseña debe de tener al menos 8 caracteres",
			passwordValidation: "Reingresar nueva contraseña",
			passwordValidationErrorMessage:
				"La validación de contraseña es requerida",
			passwordValidationMatchErrorMessage: "La contraseña es diferente",
			button: "Continuar",
			buttonAccountCreate: "Crear cuenta",
			buttonAccountCreateScreenReader: "Botón de creación de cuenta",
			buttonScreenReader: "Botón de continuar",
			selectLabel: "Precio",
			restaurantLabel: "Nombre del restaurante",
			restaurantValidationErrorMessage:
				"El nombre del restaurante es un campo requerido",
		},
		loaderAccountCreate: [
			"Preparando base de datos",
			"Creando bloque en memoria",
			"Generando identificador único",
			"Enlazando proveedores de cuenta",
			"Migrando usuario a base de datos",
			"Adjuntando información de usuario",
			"Adjuntando información de restaurante",
			"Adjuntando información de dirección",
			"Generando coordenadas de la dirección",
			"Salteando y creando hash de contraseña",
			"Tomando un martini",
			"Validando cuenta final",
			"Generando registro de creación de cuenta",
			"Redireccionando a página principal",
		],
		reservations: {
			title: "General",
			reservationNameLabel: "Nombre de la reservación",
			people: "personas",
		},
		reservation: {
			nameLabel: "Nombre",
			contactLabel: "Contacto",
			dateLabel: "Fecha",
			timeLabel: "Hora",
			consumersLabel: "Comensales",
			promotionLabel: "Promoción",
			codeLabel: "Código",
			statusLabel: "Estado",
		},
		promotion: {
			nameLabel: "Nombre",
			authorLabel: "Autor",
			descriptionLabel: "Descripción",
			promotionLabel: "Promoción",
			typeLabel: "Tipo",
			restrictionsLabel: "Restricciones",
			statusLabel: "Estado",
			noMatch:
				"Hubo un problema al buscar la promoción. Por favor verifica el ID e intenta de nuevo",
			activate: "Activar",
			deactivate: "Desactivar",
			restrictionsNone: "Sin restricciones",
			active: "Activa",
			inactive: "Inactiva",
		},
		newPromotionForm: {
			title: "Crear nueva promoción",
			description:
				"Esta página es para la creación de promociones. No te preocupes, la promoción no se activará hasta que tú lo decidas. Puedes crear diferentes tipos de promociones y utilizar el calendario para abrir y cerrar diferentes horarios. Asegúrate que tu restaurante esté verificado y validado para que tus promociones aparezcan en la aplicación móvil.",
			sectionTitle: "Información general",
			restrictions: "Restricciones",
			restrictionsButton: "Agregar restricción",
			promotionName: "Nombre de la promoción",
			promotionNameErrorMessage: "El nombre es un campo requerido",
			buttonScreenReader: "Guardar promoción",
			button: "Guardar",
			promotionAuthor: "Autor de la promoción",
			promotionAuthorErrorMessage: "El autor es un campo requerido",
			promotionType: "Tipo de promoción",
			promotionTypes: [
				"Descuento porcentual",
				"Descuento fijo",
				"Bebida gratis",
				"Plato gratis",
				"Combo (2x1, 3x2)",
			],
		},
		privacy: {
			title: "Aviso de privacidad",
		},
		settings: {},
		accountSettings: {
			update: "Editar",
			remove: "Quitar",
			title: "Cuenta",
			description: "Gestiona el formato de la información en tu cuenta",
			language: "Idioma",
			dateFormat: "Formato de fecha",
			multipleAccounts: "Múltiples cuentas",
			tablet: "Uso en tableta",
		},
		profileSettings: {
			update: "Editar",
			remove: "Quitar",
			title: "Perfil",
			description: "Esta información será vista por el usuario final",
			name: "Nombre",
			photo: "Foto",
			email: "Correo electrónico",
			restaurantDescription: "Descripción de restaurante",
		},
		addressSettings: {
			title: "Dirección",
			description:
				"Cambiar la dirección. La nueva dirección deberá de ser validada por el equipo de ventas",
		},
		passwordSettings: {
			update: "Editar",
			remove: "Quitar",
			title: "Contraseña",
			description: "Cambiar contraseña de cuenta",
		},
	},
	en: {
		signin: {
			title: "Sign in to your restaurant's account",
			or: "Or",
			link: "create an account",
			email: "Email address",
			password: "Password",
			remember: "Remember me",
			forgot: "Forgot your password?",
			signin: "Sign in",
			requiredEmail: "Email is a required field",
			emailPatternValidation:
				"Entered email does not have correct format",
			requiredPassword: "Password is a required field",
			passwordLengthValidation: "Password minimum length is 8 characters",
		},
		signup: {
			title: "Create a restaurant's account",
			or: "Or",
			link: "login",
		},
		sidebar: {
			home: "Home",
			history: "History",
			reservations: "Reservations",
			promotions: "Promotions",
			calendar: "Calendar",
			analytics: "Analytics",
			reports: "Reports",
			settings: "Settings",
			contact: "Contact",
			help: "Help",
			privacy: "Privacy",
		},
		signInProviders: {
			title: "Sign in with",
			facebookScreenReader: "Sign in with Facebook",
			twitterScreenReader: "Sign in with Twitter",
			gitHubScreenReader: "Sign in with GitHub",
			continue: "Or continue with",
		},
		recentActivity: {
			people: "people",
			reservation: "Reservation",
			promotion: "Promotion",
			status: "Status",
			date: "Date",
		},
		pagination: {
			showing: "Showing",
			to: "to",
			of: "of",
			results: "results",
			previous: "Previous",
			next: "Next",
		},
		promotionCards: {
			modalTitle: "Schedule promotion",
			modalBody:
				"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium",
			modalClose: "Close",
			modalActive: "Activate",
			active: "Active promotions",
			inactive: "Inactive promotions",
		},
		deactivatePromotionModal: {
			modalTitle: "Schedule promotion",
			modalBody:
				"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium",
			modalClose: "Close",
			modalActive: "Activate",
			active: "Active promotions",
			inactive: "Inactive promotions",
		},
		profileMenu: {
			profile: "Your profile",
			changeUser: "Change user",
			settings: "Settings",
			logout: "Logout",
			name: "Restaurant name",
			menuScreenReader: "Open menu for",
		},
		overview: {
			title: "Total reservation",
			viewAll: "View all",
		},
		navHeader: {
			sidebarScreenReader: "Open sidebar",
			createPromotion: "Create Promotion",
			notificationScreenReader: "View notifications",
		},
		infoSidebar: {
			title: "Contact information",
			body: "If you have any questions, suggestions or anything that is not urgent, use this contact form. If you need assistance now, use the widget on the bottom right.",
		},
		header: {
			title: "Welcome to the restaurant dashboard",
			address: "Pino 65 Casa 1 Col Coyoacan",
			verified: "Verified account",
			unverified: "Unverified account",
			link: "Go To Promotions",
		},
		addressForm: {
			address_1: "Address line 1",
			address_2: "Address line 2",
			city: "City",
			state: "State/Province",
			country: "Country",
			zip: "Zip/Postal code",
			buttonScreenReader: "Continue button",
			button: "Continue",
		},
		signupForm: {
			name: "Name",
			nameErrorMessage: "Name is a required field",
			surname: "Surname",
			surnameErrorMessage: "Surname is a required field",
			email: "Email address",
			emailErrorMessage: "Email is required",
			emailPatternValidation: "Email does not have correct format",
			password: "Password",
			passwordErrorMessage: "Password is required",
			passwordLengthErrorMessage:
				"Password should at least have 8 characters",
			passwordValidation: "Re-enter Password",
			passwordValidationErrorMessage: "Password validation is required",
			passwordValidationMatchErrorMessage: "Password does not match",
			button: "Continue",
			buttonAccountCreate: "Create account",
			buttonAccountCreateScreenReader: "Account create button",
			buttonScreenReader: "Continue button",
			selectLabel: "Price",
			restaurantLabel: "Restaurant name",
			restaurantValidationErrorMessage:
				"Restaurant name is a required field",
		},
		loaderAccountCreate: [
			"Preparing database",
			"Scrubbing unused space",
			"Generating unique identifier",
			"Linking account providers",
			"Migrating user to database",
			"Appending user information",
			"Appending restaurant information",
			"Appending address information",
			"Generating coordinates from address",
			"Salting and hashing password",
			"Sipping a dirty martini",
			"Validating final account",
			"Logging results",
			"Redirecting to dashboard",
		],
		reservations: {
			title: "Overview",
			reservationNameLabel: "Reservation name",
			people: "people",
		},
		reservation: {
			nameLabel: "Name",
			contactLabel: "Contact",
			dateLabel: "Date",
			timeLabel: "Time",
			consumersLabel: "Consumers",
			promotionLabel: "Promotion",
			statusLabel: "Status",
		},
		promotion: {
			nameLabel: "Name",
			authorLabel: "Author",
			descriptionLabel: "Description",
			promotionLabel: "Promotion",
			typeLabel: "Type",
			restrictionsLabel: "Restrictions",
			statusLabel: "Status",
			noMatch:
				"No ID matches this promotion. Either the promotion was deleted or the the ID is incorrect. Please verify this information and try again.",
			activate: "Activate",
			deactivate: "Deactivate",
			restrictionsNone: "No restrictions",
		},
		newPromotion: {
			title: "Create a new promotion",
			description:
				"This feature allows you to create a new promotion. Don't worry, it will not activate until you choose so. You can create different types of promotions and use the calendar tab to schedule when they open and close! Make sure your restaurant is verified so your promotion appears in the consumer app.",
			sectionTitle: "General information",
		},
		privacy: {
			privacy: {
				title: "Privacy agreement",
			},
		},
		settings: {},
		accountSettings: {
			update: "Update",
			remove: "Remove",
			title: "Account",
			description: "Manage how information is displayed on your account",
			language: "Language",
			dateFormat: "Date format",
			multipleAccounts: "Multiple accounts",
			tablet: "Using on tablet",
		},
		profileSettings: {
			update: "Update",
			remove: "Remove",
			title: "Profile",
			description:
				"This information will be displayed publicly so be careful what you share",
			name: "Name",
			photo: "Photo",
			email: "Email",
			restaurantDescription: "Restaurant description",
		},
	},
};

export default localization;
