import daysInWeek from "@constants/daysInWeek";
import timeSlots from "@constants/timeSlots";

import { secondsToStringTime, stringTimeToSeconds } from "@utils/parseHour";

import TimePicker from "@molecules/TimePicker";

import DayBubble from "@atoms/DayBubble";

const SchedulePicker = ({ schedule, dispatch }) => {
	return (
		<div className="grid grid-cols-1 my-4 sm:grid-cols-2">
			{daysInWeek.map(({ label, min, day }) => {
				return (
					<div className="flex items-center my-4 gap-x-4" key={label}>
						<DayBubble
							status={schedule[day].status}
							dispatch={dispatch}
							text={min}
							index={day}
						/>
						<div className="flex items-center gap-x-4">
							<TimePicker
								timeSlots={timeSlots}
								disabled={!schedule[day].status}
								state={secondsToStringTime(schedule[day].open)}
								setState={(e) => {
									dispatch({
										type: "change_open_time",
										payload: {
											index: day,
											open: stringTimeToSeconds(e),
										},
									});
								}}
							/>
							<div className="text-primary-600">—</div>
							<TimePicker
								timeSlots={timeSlots}
								disabled={!schedule[day].status}
								state={secondsToStringTime(schedule[day].close)}
								setState={(e) => {
									dispatch({
										type: "change_close_time",
										payload: {
											index: day,
											close: stringTimeToSeconds(e),
										},
									});
								}}
							/>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default SchedulePicker;
