import createDataContext from "@context/createDataContext";

import uploadFileGetLink from "@utils/uploadFileGetLink";
import geocodeAddress from "@utils/geocoder";
import { database, doc, getDoc, updateDoc, arrayUnion } from "@utils/firebase";

const reducer = (state, action) => {
	switch (action.type) {
		case "read_restaurant":
			return action.payload;
		case "update_amenities":
			return {
				...state,
				data: { ...state.data, amenities: action.payload.amenities },
			};
		case "update_schedule":
			return {
				...state,
				data: { ...state.data, fullSchedule: action.payload },
			};
		case "add_image":
			return {
				...state,
				data: {
					...state.data,
					images: [...state.data.images, action.payload],
				},
			};
		case "update_logo":
			return {
				...state,
				data: {
					...state.data,
					logo: action.payload,
				},
			};
		case "update_image_order":
			return {
				...state,
				data: { ...state.data, images: action.payload },
			};
		case "delete_image":
			return {
				...state,
				data: {
					...state.data,
					images: state.data.images.filter((url) => {
						return url !== action.payload;
					}),
				},
			};
		case "add_document":
			return {
				...state,
				data: {
					...state.data,
					documents: [...state.data.documents, action.payload],
				},
			};

		case "delete_document":
			return {
				...state,
				data: {
					...state.data,
					documents: state.data.documents.filter((url) => {
						return url !== action.payload;
					}),
				},
			};
		case "reset":
			return action.payload;
		default:
			return state;
	}
};

const initialRestaurant = { data: {}, isLoaded: false };

const getRestaurant = (dispatch) => {
	return async (restaurantId) => {
		const restaurantRef = doc(database, "restaurants", restaurantId);

		try {
			const restaurant = await getDoc(restaurantRef);
			const isLoaded = true;

			dispatch({
				type: "read_restaurant",
				payload: { data: restaurant.data(), isLoaded },
			});
		} catch (error) {
			console.log(error);
			throw error;
		}
	};
};

const updateAmenities = (dispatch) => {
	return async (restaurantId, amenities, navigation) => {
		console.log(restaurantId, amenities, navigation);
		const restaurantRef = doc(database, "restaurants", restaurantId);

		try {
			await updateDoc(restaurantRef, { amenities });

			dispatch({
				type: "update_amenities",
				payload: { amenities },
			});
			navigation && navigation();
		} catch (error) {
			console.log(error);
			throw error;
		}
	};
};
const updateSchedule = (dispatch) => {
	return async (restaurantId, schedule, navigation) => {
		const restaurantRef = doc(database, "restaurants", restaurantId);

		try {
			await updateDoc(restaurantRef, { fullSchedule: schedule });
			dispatch({
				type: "update_schedule",
				payload: schedule,
			});
			navigation && navigation();
		} catch (error) {
			console.log(error);
			throw error;
		}
	};
};
const updateAddress = (dispatch) => {
	return async (restaurantId, data, navigation) => {
		const { address_1, city, state, country, zip } = data;
		const restaurantRef = doc(database, "restaurants", restaurantId);
		try {
			const geoCodeObject = { address_1, city, state, country, zip };
			const coordinates = geocodeAddress(geoCodeObject);
			const payload = { ...data, coordinates };
			await updateDoc(restaurantRef, payload);
			dispatch({
				type: "update_schedule",
				payload,
			});
			navigation && navigation();
		} catch (error) {
			console.log(error);
			throw error;
		}
	};
};
const addImage = (dispatch) => {
	return async (restaurantId, image) => {
		const ref = doc(database, "restaurants", restaurantId);

		try {
			await updateDoc(ref, {
				images: arrayUnion(image),
			});
			console.log("Images updated successfully");
			dispatch({
				type: "add_image",
				payload: image,
			});
		} catch (error) {
			console.log("Error adding document: ", error);
		}
	};
};
const updateLogo = (dispatch) => {
	return async (restaurantId, logo, callback) => {
		const ref = doc(database, "restaurants", restaurantId);
		await uploadFileGetLink(restaurantId, logo, "logo", logo.name, false)
			.then((url) => {
				try {
					updateDoc(ref, {
						logo: url,
					});
					console.log("Images updated successfully");
					dispatch({
						type: "update_logo",
						payload: url,
					});
					callback && callback;
				} catch (error) {
					console.log("Error adding document: ", error);
				}
			})
			.catch((error) => {
				return console.log(error);
			});
	};
};

const changeImageOrder = (dispatch) => {
	return async (restaurantId, imageArr) => {
		const ref = doc(database, "restaurants", restaurantId);

		try {
			await updateDoc(ref, {
				images: imageArr,
			});
			console.log("Images updated successfully");
			dispatch({
				type: "update_image_order",
				payload: imageArr,
			});
			return "a";
		} catch (error) {
			console.log("Error adding document: ", error);
		}
	};
};

const deleteImage = (dispatch) => {
	return async (restaurantId, image, images) => {
		const ref = doc(database, "restaurants", restaurantId);

		try {
			await updateDoc(ref, {
				images: images.filter((url) => {
					return url !== image;
				}),
			}).then(() => {
				console.log("Image deleted successfully");
				dispatch({ type: "delete_image", payload: image });
			});
		} catch (error) {
			console.log(error);
			throw error;
		}
	};
};

const addDocument = (dispatch) => {
	return async (restaurantId, document, name) => {
		const ref = doc(database, "restaurants", restaurantId);
		try {
			await updateDoc(ref, {
				documents: arrayUnion({
					name,
					url: document,
				}),
			});
			console.log("Documents updated successfully");
			dispatch({
				type: "add_document",
				payload: { name, url: document },
			});
		} catch (error) {
			console.log("Error adding document: ", error);
		}
	};
};

const deleteDocument = (dispatch) => {
	return async (restaurantId, document, documents) => {
		const ref = doc(database, "restaurants", restaurantId);

		try {
			await updateDoc(ref, {
				documents: documents.filter((url) => {
					return url !== document;
				}),
			}).then(() => {
				console.log("Document deleted successfully");
				dispatch({ type: "delete_document", payload: document });
			});
		} catch (error) {
			console.log(error);
		}
	};
};

const resetRestaurantContext = (dispatch) => {
	return async () => {
		dispatch({ type: "reset", payload: initialRestaurant });
	};
};

export const { Context, Provider } = createDataContext(
	reducer,
	{
		getRestaurant,
		updateAmenities,
		updateSchedule,
		addImage,
		updateLogo,
		changeImageOrder,
		deleteImage,
		addDocument,
		deleteDocument,
		updateAddress,
		resetRestaurantContext,
	},
	initialRestaurant,
);
