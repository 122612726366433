import axios from "axios";

import createDataContext from "@context/createDataContext";

import { request } from "@constants/placesAPI";

import { parseAddress } from "@utils/placesParser";
import { GeoPoint } from "@utils/firebase";
import googleScheduleParser from "@utils/googleScheduleParser";

const reducer = (state, action) => {
	switch (action.type) {
		case "update_primary_info":
			return {
				...state,
				name: action.payload.name,
				surname: action.payload.surname,
				email: action.payload.email,
			};
		case "update_secondary_info":
			return {
				...state,
				price: action.payload.price,
				categories: action.payload.categories,
				phone: action.payload.phone,
				website: action.payload.website,
			};
		case "update_secondary_info_manually":
			return {
				...state,
				price: action.payload.price,
				categories: action.payload.categories,
				restaurant_name: action.payload.restaurant_name,
				currentRestaurantId: action.payload.currentRestaurantId,
				phone: action.payload.phone,
				website: action.payload.website,
			};
		case "update_address_info":
			return {
				...state,
				address_1: action.payload.address_1,
				address_2: action.payload.address_2,
				city: action.payload.city,
				state: action.payload.state,
				country: action.payload.country,
				zip: action.payload.zip,
			};
		case "update_address_info_manually":
			return {
				...state,
				address_1: action.payload.address_1,
				address_2: action.payload.address_2,
				city: action.payload.city,
				state: action.payload.state,
				country: action.payload.country,
				zip: action.payload.zip,
				coordinates: action.payload.coordinates,
			};
		case "update_google_api_hydration":
			return {
				...state,
				address_1: action.payload.address_1,
				address_2: action.payload.address_2,
				city: action.payload.city,
				state: action.payload.state,
				country: action.payload.country,
				zip: action.payload.zip,
				coordinates: action.payload.coordinates,
				restaurant_name: action.payload.restaurant_name,
				currentRestaurantId: action.payload.currentRestaurantId,
				fullSchedule: action.payload.fullSchedule,
				schedule: action.payload.schedule,
				images: action.payload.images,
				price: action.payload.price,
				phone: action.payload.phone,
				rating: action.payload.rating,
				user_ratings_total: action.payload.user_ratings_total,
				website: action.payload.website,
				isUsingGoogleApi: action.payload.isUsingGoogleApi,
			};
		case "update_amenities":
			return {
				...state,
				amenities: action.payload,
			};
		case "update_password_info":
			return {
				...state,
				password: action.payload.password,
			};
		case "update_images":
			return {
				...state,
				images: action.payload.data,
			};
		case "update_custom":
			return {
				...state,
				[action.payload.key]: action.payload.data,
			};
		default:
			throw Error;
	}
};

const initialSignup = {
	address_1: "",
	address_2: "",
	amenities: [],
	categories: [],
	city: "",
	coordinates: {},
	country: "",
	currentRestaurantId: null,
	documents: [],
	email: "",
	fullSchedule: [
		{ status: false, open: 36000, close: 75600 },
		{ status: false, open: 36000, close: 75600 },
		{ status: false, open: 36000, close: 75600 },
		{ status: false, open: 36000, close: 75600 },
		{ status: false, open: 36000, close: 75600 },
		{ status: false, open: 36000, close: 75600 },
		{ status: false, open: 36000, close: 75600 },
	],
	images: [],
	isUsingGoogleApi: false,
	logo: null,
	name: "",
	phone: "",
	price: 3,
	rating: null,
	restaurant_name: "",
	schedule: { start: "", end: "" },
	state: "",
	surname: "",
	user_ratings_total: null,
	zip: "",
	password: "",
	website: "",
};

const updatePrimaryInfo = (dispatch) => {
	return ({ name, surname, email }) => {
		dispatch({
			type: "update_primary_info",
			payload: { name, surname, email },
		});
	};
};
const updateSecondaryInfo = (dispatch) => {
	return ({ price, categories, phone, website }) => {
		dispatch({
			type: "update_secondary_info",
			payload: {
				price,
				categories,
				phone,
				website,
			},
		});
	};
};

const updateSecondaryInfoManually = (dispatch) => {
	return ({ price, categories, restaurant_name, phone, website }) => {
		dispatch({
			type: "update_secondary_info_manually",
			payload: {
				price,
				categories,
				restaurant_name,
				currentRestaurantId: "",
				website,
				phone,
			},
		});
	};
};

const updateAddressInfo = (dispatch) => {
	return ({ address_1, address_2, city, state, country, zip }) => {
		dispatch({
			type: "update_address_info",
			payload: {
				address_1,
				address_2,
				city,
				state,
				country,
				zip,
			},
		});
	};
};
const updateAddressInfoManually = (dispatch) => {
	return ({
		address_1,
		address_2,
		city,
		state,
		country,
		zip,
		coordinates,
	}) => {
		dispatch({
			type: "update_address_info_manually",
			payload: {
				address_1,
				address_2,
				city,
				state,
				country,
				zip,
				coordinates: new GeoPoint(
					coordinates.latitude,
					coordinates.longitude,
				),
			},
		});
	};
};
const updateAmenities = (dispatch) => {
	return (amenities) => {
		dispatch({
			type: "update_amenities",
			payload: amenities,
		});
	};
};
const updatePasswordInfo = (dispatch) => {
	return ({ password }) => {
		dispatch({
			type: "update_password_info",
			payload: { password },
		});
	};
};

const queryGoogleData = (dispatch) => {
	return async (placeId, currentRestaurantId, setIsLoading) => {
		if (currentRestaurantId !== placeId) {
			try {
				const service = new google.maps.places.PlacesService(
					document.createElement("div"),
				);
				service.getDetails(
					request(placeId),
					async (restaurant, status) => {
						if (
							status === google.maps.places.PlacesServiceStatus.OK
						) {
							console.log("GOOGLE", restaurant);
							const rawAddress = restaurant.address_components;
							const parsedAddress = parseAddress(rawAddress);
							const address_1 =
								`${parsedAddress.streetNumber} ${parsedAddress.street} ${parsedAddress.subpremise}`.trim();
							const address_2 =
								`${parsedAddress.neighborhood} ${parsedAddress.sublocality} ${parsedAddress.county}`.trim();

							const data = {
								address_1,
								address_2,
								city: parsedAddress.city,
								state: parsedAddress.state,
								country: parsedAddress.country,
								zip: parsedAddress.postalCode,
								coordinates: new GeoPoint(
									restaurant.geometry.location.lat(),
									restaurant.geometry.location.lng(),
								),
								currentRestaurantId: placeId,
								restaurant_name: restaurant.name,
								fullSchedule: [
									{
										status: false,
										open: 36000,
										close: 75600,
									},
									{
										status: false,
										open: 36000,
										close: 75600,
									},
									{
										status: false,
										open: 36000,
										close: 75600,
									},
									{
										status: false,
										open: 36000,
										close: 75600,
									},
									{
										status: false,
										open: 36000,
										close: 75600,
									},
									{
										status: false,
										open: 36000,
										close: 75600,
									},
									{
										status: false,
										open: 36000,
										close: 75600,
									},
								],
								schedule: { start: "", end: "" },
								images: [],
								rating: null,
								user_ratings_total: null,
								website: "",
								price: 3,
								phone: "",
								isUsingGoogleApi: true,
							};

							if ("opening_hours" in restaurant) {
								const rawSchedule =
									restaurant.opening_hours.periods;
								data.fullSchedule =
									googleScheduleParser(rawSchedule);
							}

							if ("photos" in restaurant) {
								const parsedImages = [];
								restaurant.photos.forEach((photo) => {
									return parsedImages.push(photo.getUrl());
								});
								await axios
									.post(
										"https://us-central1-ultima-mesa.cloudfunctions.net/fetchAndFind",
										{ data: parsedImages },
									)
									.then((response) => {
										if (response.status === 200) {
											data.images = response.data.data;
											setIsLoading(false);
										} else {
											console.log(response.status);
											data.images = parsedImages;
											setIsLoading(false);
										}
									})
									.catch((error) => {
										return console.log(error);
									});
							}

							if ("price_level" in restaurant) {
								data.price = restaurant.price_level;
							}
							if ("rating" in restaurant) {
								if (!restaurant.rating) {
									data.rating = null;
								} else {
									data.rating = restaurant.rating;
								}
							}
							if ("user_ratings_total" in restaurant) {
								if (!restaurant.user_ratings_total) {
									data.user_ratings_total = null;
								} else {
									data.user_ratings_total =
										restaurant.user_ratings_total;
								}
							}
							if ("website" in restaurant) {
								data.website = restaurant.website;
							}
							if ("formatted_phone_number" in restaurant) {
								data.phone =
									restaurant.formatted_phone_number.replace(
										/\s/g,
										"",
									);
							}

							dispatch({
								type: "update_google_api_hydration",
								payload: data,
							});
						} else {
							console.log(status);
						}
					},
				);
			} catch (error) {
				console.log(error);
			}
		}
	};
};

export const { Context, Provider } = createDataContext(
	reducer,
	{
		updatePrimaryInfo,
		updateSecondaryInfo,
		updateAddressInfo,
		updateAmenities,
		updatePasswordInfo,
		queryGoogleData,
		updateSecondaryInfoManually,
		updateAddressInfoManually,
	},
	initialSignup,
);
