import { lazy } from "react";

import withFallback from "@HOC/withFallback";

const Header = withFallback(
	lazy(() => {
		return import("@organisms/Header");
	}),
);
const Overview = withFallback(
	lazy(() => {
		return import("@organisms/Overview");
	}),
);
const RecentActivity = withFallback(
	lazy(() => {
		return import("@organisms/RecentActivity");
	}),
);

const Home = () => {
	return (
		<main>
			<Header />
			<Overview />
			<RecentActivity />
		</main>
	);
};

export default Home;
