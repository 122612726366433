const daysInWeek = [
	{ day: 1, label: "Lunes", min: "L" },
	{ day: 2, label: "Martes", min: "Ma" },
	{ day: 3, label: "Miércoles", min: "Mi" },
	{ day: 4, label: "Jueves", min: "J" },
	{ day: 5, label: "Viernes", min: "V" },
	{ day: 6, label: "Sábado", min: "S" },
	{ day: 0, label: "Domingo", min: "D" },
];

export default daysInWeek;
