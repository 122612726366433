export const timeToSeconds = (time, period) => {
	const periodModifier = period === "AM" ? 0 : 43200;
	return (
		parseInt(time.hour) * 3600 + parseInt(time.minute) * 60 + periodModifier
	);
};

export const secondsToTime = (time) => {
	let hours = Math.floor(time / 3600);
	let minutes = (time % 3600) / 60;
	if (hours < 10) {
		hours = `0${hours}`;
	}
	if (minutes < 10) {
		minutes = `0${minutes}`;
	}
	return `${hours}:${minutes}`;
};

export const timeToInt = (schedule) => {
	return (
		parseInt(schedule.slice(0, 2)) * 3600 +
		parseInt(schedule.slice(-2)) * 60
	);
};

export const stringTimeToSeconds = (time) => {
	const strArr = time.split("");
	const indexToRemove = strArr.indexOf(":");
	strArr.splice(indexToRemove, 1);
	const arrLength = strArr.length;
	const parsedArr = strArr.join("");
	if (arrLength === 3) {
		const hours = parseInt(parsedArr.substring(0, 1)) * 3600;
		const minutes = parseInt(parsedArr.substring(1, 3)) * 60;
		return hours + minutes;
	}
	const hours = parseInt(parsedArr.substring(0, 2)) * 3600;
	const minutes = parseInt(parsedArr.substring(2, 4)) * 60;
	return hours + minutes;
};

export const secondsToStringTime = (time) => {
	const hours = Math.floor(time / 3600).toString();
	let minutes = ((time % 3600) / 60).toString();
	if (minutes.length === 1) {
		minutes = `0${minutes}`;
	}
	const parsedTime = `${hours}:${minutes}`;
	return parsedTime;
};
