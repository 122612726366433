import createDataContext from "@context/createDataContext";

import {
	database,
	doc,
	updateDoc,
	getDocs,
	where,
	query,
	collection,
} from "@utils/firebase";
import parseDate from "@utils/parseDate";

const reducer = (state, action) => {
	switch (action.type) {
		case "create_reservations":
			return {
				...state,
				data: [...state.data, { ...action.payload.data }],
			};
		case "read_reservations":
			return action.payload;
		case "update_reservations":
			return {
				...state,
				data: state.data.map((reservation) => {
					return reservation.id === action.payload.reservationId
						? { ...reservation, status: action.payload.status }
						: reservation;
				}),
			};
		case "delete_reservation":
			return {
				...state,
				data: state.data.filter((reservation) => {
					return reservation.id !== action.payload;
				}),
			};
		default:
			return state;
	}
};

const getReservations = (dispatch) => {
	return async (restaurantId) => {
		const reservations = [];
		const ref = query(
			collection(database, "reservations"),
			where("restaurantId", "==", restaurantId),
		);

		try {
			const querySnapshot = await getDocs(ref);
			querySnapshot.forEach((doc) => {
				const date = parseDate(doc.data().date.seconds);
				const parsedDocument = { ...doc.data(), id: doc.id, date };
				reservations.push(parsedDocument);
			});
			const status = "success";

			dispatch({
				type: "read_reservations",
				payload: { data: reservations, status },
			});
		} catch (error) {
			console.log(error);
		}
	};
};

const completeReservation = (dispatch) => {
	return async (reservationId) => {
		const ref = doc(database, "reservations", reservationId);

		try {
			await updateDoc(ref, {
				status: "completed",
			});
			dispatch({
				type: "update_reservations",
				payload: { reservationId, status: "completed" },
			});
		} catch (error) {
			console.log(error);
		}
	};
};

const cancelReservation = (dispatch) => {
	return async (reservationId) => {
		const ref = doc(database, "reservations", reservationId);

		try {
			await updateDoc(ref, {
				status: "canceled",
			});
			dispatch({
				type: "update_reservations",
				payload: { reservationId, status: "canceled" },
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const { Context, Provider } = createDataContext(
	reducer,
	{ getReservations, completeReservation, cancelReservation },
	{ data: [], status: "idle" },
);
