import createDataContext from "@context/createDataContext";

import {
	database,
	getDoc,
	updateDoc,
	setDoc,
	doc,
	Timestamp,
	arrayUnion,
} from "@utils/firebase";

const reducer = (state, action) => {
	switch (action.type) {
		case "create_promotion":
			return {
				...state,
				data: [...state.data, { ...action.payload.data }],
			};
		case "read_promotions":
			return action.payload;
		case "toggle_promotion":
			return { ...state, data: action.payload };
		case "update_promotion":
			return {
				...state,
				data: state.data.map((promotion) => {
					return promotion.id === action.payload.promotionId
						? {
								...promotion,
								name: action.payload.name,
								type: action.payload.type,
								description: action.payload.description,
						  }
						: promotion;
				}),
			};
		case "delete_promotion":
			return { ...state, data: action.payload };
		case "reset":
			return action.payload;
		default:
			return state;
	}
};

const initialPromotions = { data: [], isLoaded: false };

const getPromotions = (dispatch) => {
	return async (restaurantId) => {
		try {
			const promotionRef = doc(database, "promotions", restaurantId);
			const querySnapshot = await getDoc(promotionRef);
			if (querySnapshot.exists()) {
				const isLoaded = true;
				const parsedPromotions = querySnapshot
					.data()
					.data.map((promo) => {
						return promo.promotion;
					});
				dispatch({
					type: "read_promotions",
					payload: { data: parsedPromotions, isLoaded },
				});
			} else {
				console.log("No promotions");
			}
		} catch (error) {
			console.log(error);
		}
	};
};

const createPromotion = (dispatch) => {
	return async (
		user,
		{ name, restrictions, description, data },
		currentPromotions,
		navigationCallback,
	) => {
		const promotionRef = doc(database, "promotions", user.uid);
		try {
			const payload = {
				promotion: {
					name,
					data,
					restrictions,
					description,
					active: false,
					deleted: false,
					restaurantId: user.uid,
					index:
						currentPromotions.length === 0
							? 0
							: currentPromotions.length,
				},
			};
			if (currentPromotions.length === 0) {
				await setDoc(promotionRef, {
					data: [payload],
				});
				console.log("Promotion created successfully: ", payload);
				dispatch({
					type: "create_promotion",
					payload: { data: payload.promotion },
				});
			} else {
				await updateDoc(promotionRef, {
					data: arrayUnion(payload),
				});
				console.log("Promotion created successfully: ", payload);
				dispatch({
					type: "create_promotion",
					payload: { data: payload.promotion },
				});
			}

			navigationCallback && navigationCallback();
		} catch (error) {
			console.log("Error adding document: ", error);
		}
	};
};

const deletePromotion = (dispatch) => {
	return async (restaurantId, promotions, indexToDelete, navigation) => {
		console.log("id", restaurantId);
		const promotionRef = doc(database, "promotions", restaurantId);
		try {
			const parsedPromotions = promotions.map((promo, i) => {
				console.log(promo, i);
				return i === indexToDelete
					? { promotion: { ...promo, deleted: true } }
					: { promotion: promo };
			});
			await updateDoc(promotionRef, {
				data: parsedPromotions,
			});
			console.log("Promotion deleted successfully");
			dispatch({
				type: "delete_promotion",
				payload: parsedPromotions.map((promotion) => {
					return promotion.promotion;
				}),
			});

			navigation && navigation();
		} catch (error) {
			console.log(error);
		}
	};
};

const togglePromotion = (dispatch) => {
	return async (
		restaurantId,
		active,
		data,
		index,
		promotions,
		navigationCallback,
	) => {
		const ref = doc(database, "promotions", restaurantId);
		const { days, time, recurring, date } = data;
		const parsedDate = date
			? {
					recurring,
					start: Timestamp.fromDate(date.from),
					end: Timestamp.fromDate(date.to),
			  }
			: { recurring, start: false, end: false };
		try {
			console.log(1212112112, index);
			const parsedPromotions = promotions.map((promo, i) => {
				return i === index
					? {
							promotion: {
								...promo,
								active,
								schedule: {
									days,
									time,
									timeframe: parsedDate,
								},
							},
					  }
					: { promotion: promo };
			});
			console.log(parsedPromotions);

			await updateDoc(ref, { data: parsedPromotions });
			console.log(`Promotion was set to: ${active}`);
			dispatch({
				type: "toggle_promotion",

				payload: parsedPromotions.map((promotion) => {
					return promotion.promotion;
				}),
			});
			console.log(
				parsedPromotions.map((promotion) => {
					return promotion.promotion;
				}),
			);
			navigationCallback && navigationCallback();
		} catch (error) {
			console.log("Error changing active state: ", error);
		}
	};
};

const updatePromotion = (dispatch) => {
	return async (promotionId, data, navigationCallback) => {
		const ref = database.collection("promotions").doc(promotionId);
		const { name, type, description } = data;
		try {
			await ref.update({
				name,
				type,
				description,
			});
			console.log("Promotion was updated successfully");
			dispatch({
				type: "update_promotion",
				payload: { promotionId, name, type, description },
			});
			navigationCallback && navigationCallback();
		} catch (error) {
			console.log("Error updating promotion: ", error);
		}
	};
};

const resetPromotionContext = (dispatch) => {
	return async () => {
		dispatch({ type: "reset", payload: initialPromotions });
	};
};

export const { Context, Provider } = createDataContext(
	reducer,
	{
		getPromotions,
		createPromotion,
		togglePromotion,
		deletePromotion,
		updatePromotion,
		resetPromotionContext,
	},
	initialPromotions,
);
