import {
	ShieldExclamationIcon,
	HomeIcon,
	PlayIcon,
	PhotographIcon,
	ClipboardListIcon,
	FingerPrintIcon,
	ShieldCheckIcon,
	CubeTransparentIcon,
	ClockIcon,
	ClipboardCheckIcon,
	IdentificationIcon,
} from "@heroicons/react/outline";

import useLocalization from "@hooks/useLocalization";

const Navigation = () => {
	const [{ sidebar: localization }] = useLocalization();

	const infoLinks = [
		{
			name: localization.home,
			icon: HomeIcon,
			route: "/",
			futureFeature: false,
		},
		{
			name: localization.promotions,
			icon: PlayIcon,
			route: "/promotions",
			futureFeature: false,
		},
	];

	const otherLinks = [
		{
			name: "Verificación",
			icon: ClipboardCheckIcon,
			route: "/verification",
		},
		{ name: "Contraseña", icon: FingerPrintIcon, route: "/password" },
		{ name: "Logotipo", icon: IdentificationIcon, route: "/logo" },
		{ name: "Amenidades", icon: CubeTransparentIcon, route: "/amenities" },
		{ name: "Horarios", icon: ClockIcon, route: "/schedule" },
		{ name: "Imágenes", icon: PhotographIcon, route: "/images" },
		{ name: "Menús", icon: ClipboardListIcon, route: "/menus" },
		{
			name: localization.privacy,
			icon: ShieldCheckIcon,
			route: "/privacy",
		},
		{
			name: localization.terms,
			icon: ShieldExclamationIcon,
			route: "/terms",
		},
	];
	return [infoLinks, otherLinks];
};

export default Navigation;
