import { Link } from "react-router-dom";
import { UserIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Transition, Menu } from "@headlessui/react";

import { useAuth } from "@useAuth";
import useLocalization from "@hooks/useLocalization";
import useRestaurant from "@hooks/useRestaurant";

import hasOwnProperty from "@utils/hasOwnProperty";

import ScreenReader from "@atoms/ScreenReader";

const ProfileMenu = () => {
	const [{ profileMenu: localization }] = useLocalization();

	const auth = useAuth();
	const [restaurant] = useRestaurant();

	const logoutNavigate = () => {
		return auth.signout();
	};

	const menuItems = [
		{
			text: localization.logout,
			type: "button",
			action: logoutNavigate,
			disabled: false,
		},
	];

	return (
		<Menu>
			{({ open }) => {
				return (
					<>
						<Menu.Button className="flex items-center max-w-xs text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
							{hasOwnProperty(restaurant, "images") ? (
								restaurant.images.length > 0 ||
								restaurant.logo ? (
									<img
										className="w-8 h-8 rounded-full"
										src={
											restaurant.logo ||
											restaurant.images[0]
										}
										alt="profile"
									/>
								) : (
									<UserIcon className="w-8 h-8 p-1 text-gray-400 border border-gray-400 rounded-full" />
								)
							) : null}
							<span className="hidden ml-3 text-sm font-medium text-gray-700 lg:block">
								<ScreenReader
									dictation={localization.menuScreenReader}
								/>
								{auth.user.displayName
									? auth.user.displayName
									: localization.name}
							</span>
							<ChevronDownIcon className="flex-shrink-0 hidden w-5 h-5 ml-1 text-gray-400 lg:block" />
						</Menu.Button>

						<Transition
							show={open}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<Menu.Items
								className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
								role="menu"
								aria-orientation="vertical"
								aria-labelledby="user-menu"
							>
								{menuItems.map((body, i) => {
									return body.type === "link" ? (
										<Menu.Item
											key={i}
											disabled={body.disabled}
										>
											{({ active }) => {
												return (
													<Link
														to={body.action}
														className={`block px-4 py-2 text-sm ${
															active
																? "bg-primary-50 text-gray-900"
																: "bg-white text-gray-700"
														} ${
															body.disabled
																? "bg-gray-100 cursor-not-allowed pointer-events-none"
																: ""
														}`}
														role="menuitem"
													>
														{body.text}
													</Link>
												);
											}}
										</Menu.Item>
									) : (
										<Menu.Item key={i}>
											{({ active }) => {
												return (
													<button
														onClick={logoutNavigate}
														className={`block px-4 py-2 text-sm w-full text-left ${
															active
																? "bg-primary-50 text-gray-900"
																: "bg-white text-gray-700"
														}`}
														role="menuitem"
													>
														{body.text}
													</button>
												);
											}}
										</Menu.Item>
									);
								})}
							</Menu.Items>
						</Transition>
					</>
				);
			}}
		</Menu>
	);
};

export default ProfileMenu;
