import { lazy } from "react";

import usePromotions from "@hooks/usePromotions";

import withFallback from "@HOC/withFallback";

const PromotionCards = withFallback(
	lazy(() => {
		return import("@organisms/PromotionCards");
	}),
);

const Promotions = () => {
	const [promotions] = usePromotions();

	return (
		<main className="mt-8">
			<PromotionCards active={true} state={promotions} />
			<PromotionCards active={false} state={promotions} />
		</main>
	);
};

export default Promotions;
