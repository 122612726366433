const months = [
	"Enero",
	"Febrero",
	"Marzo",
	"Abril",
	"Mayo",
	"Junio",
	"Julio",
	"Agosto",
	"Septiembre",
	"Octubre",
	"Noviembre",
	"Diciembre",
];
const dateParser = (seconds) => {
	const unparsedDate = new Date(seconds * 1000);
	const year = unparsedDate.getFullYear();
	const month = months[unparsedDate.getMonth()];
	const day = unparsedDate.getDate();
	const hour = unparsedDate.getHours();
	let minute = unparsedDate.getMinutes();

	if (minute < 10) {
		minute = `0${minute}`;
	}
	const parsedTime = `${hour}:${minute}`;
	const parsedDate = `${month} ${day}, ${year}`;

	return { day: parsedDate, time: parsedTime };
};
export const firebaseDateParser = (date) => {
	// let year = date.getFullYear();
	const month = months[date.getMonth()];
	const day = date.getDate();

	// let parsedDate = `${month} ${day}, ${year}`;
	const parsedDate = `${month.substring(0, 3)}. ${day}`;

	return parsedDate;
};

export default dateParser;
