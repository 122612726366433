import { Link } from "react-router-dom";

import ScreenReader from "@atoms/ScreenReader";

const Button = ({
	Icon,
	type,
	iconDirection,
	onClick,
	screenReader,
	text,
	className,
	to = false,
	color,
	disabled = false,
}) => {
	return !to ? (
		type === "icon" ? (
			<button
				type={"button"}
				onClick={onClick}
				disabled={disabled}
				className={`${className ? className : ""} ${
					Icon ? "inline-flex" : ""
				} bg-secondary-500 hover:bg-secondary-500 focus:ring-secondary-600  my-4 items-center p-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-gray-400 disabled:cursor-not-allowed`}
			>
				{screenReader ? (
					<ScreenReader dictation={screenReader} />
				) : null}
				<Icon className="w-5 h-5 text-white" />
			</button>
		) : (
			<button
				type={type === "submit" ? "submit" : "button"}
				onClick={onClick}
				disabled={disabled}
				className={`${className ? className : ""} ${
					Icon ? "inline-flex" : ""
				} items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
					color ? color : "bg-primary-600 hover:bg-primary-700"
				}  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:bg-gray-400 disabled:cursor-not-allowed`}
			>
				{screenReader ? (
					<ScreenReader dictation={screenReader} />
				) : null}
				{iconDirection === "left" ? (
					<>
						<Icon className="w-5 h-5 mr-3 -ml-1 text-white" />
						{text}
					</>
				) : null}

				{iconDirection === "right" ? (
					<>
						{text}
						<Icon className="w-5 h-5 ml-3 -mr-1 text-white" />
					</>
				) : null}
				{!iconDirection ? text : null}
			</button>
		)
	) : (
		<Link
			to={to}
			className={`${className ? className : ""} ${
				Icon ? "inline-flex" : ""
			} items-center px-2 py-2 sm:px-4 sm:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
				color ? color : "bg-primary-600 hover:bg-primary-700"
			}  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
		>
			{screenReader ? <ScreenReader dictation={screenReader} /> : null}
			{iconDirection === "left" ? (
				<>
					<Icon className="w-5 h-5 mr-3 -ml-1 text-white" />
					{text}
				</>
			) : null}

			{iconDirection === "right" ? (
				<>
					{text}
					<Icon className="w-5 h-5 ml-3 -mr-1 text-white" />
				</>
			) : null}
			{!iconDirection ? text : null}
		</Link>
	);
};

export const FormButton = ({ Icon, text, className, onClick, rounded }) => {
	return (
		<div className={`${className ? className : ""}`}>
			<button
				onClick={onClick}
				type="button"
				className={`${rounded} relative transition duration-100 w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 cursor-pointer hover:ring-2 hover:ring-primary-400 hover:ring-offset-4 hover:ring-offset-white focus:outline-none focus:ring-2 focus:ring-opacity-75 focus:ring-white focus:ring-offset-primary-400 focus:ring-offset-2 focus:border-transparent sm:text-sm`}
			>
				<span className="block truncate">{text}</span>
				<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
					<Icon className="w-5 h-5 text-primary-500" />
				</span>
			</button>
		</div>
	);
};

export default Button;
