import createDataContext from "@context/createDataContext";

import { database, collection, getDocs } from "@utils/firebase";

const reducer = (state, action) => {
	switch (action.type) {
		case "read_categories":
			return action.payload;
		default:
			return state;
	}
};

const getCategories = (dispatch) => {
	return async () => {
		const categories = [];

		try {
			const querySnapshot = await getDocs(
				collection(database, "categories"),
			);

			querySnapshot.forEach((doc) => {
				const parsedDocument = { ...doc.data(), id: doc.id };
				categories.push(parsedDocument);
			});
			const status = "success";

			dispatch({
				type: "read_categories",
				payload: { categories, status },
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const { Context, Provider } = createDataContext(
	reducer,
	{ getCategories },
	{ categories: [], status: "idle" },
);
