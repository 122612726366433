import { useRef } from "react";
import { CheckCircleIcon } from "@heroicons/react/outline";

import Modal from "@molecules/Modal";
import ModalBody from "@molecules/ModalBody";

const SuccessModal = ({
	showModal,
	toggleModal,
	activeAction,
	title,
	description,
	activeButtonText,
}) => {
	const cancelButtonRef = useRef();

	return (
		<Modal
			isOpen={showModal}
			onClose={() => {
				return toggleModal(false);
			}}
			ref={cancelButtonRef}
		>
			<ModalBody
				onlyActive
				icon={<CheckCircleIcon className="w-6 h-6 text-primary-600" />}
				title={title}
				content={
					<>
						<p className="mb-4 text-sm text-gray-500">
							{description}
						</p>
					</>
				}
				setIsOpen={toggleModal}
				activeButton={activeButtonText}
				activeAction={activeAction}
				ref={cancelButtonRef}
			/>
		</Modal>
	);
};

export default SuccessModal;
