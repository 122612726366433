import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";

import Label from "@atoms/Label";

const Select = ({
	// register,
	options,
	name,
	className,
	label,
	state,
	setState,
	check = true,
	rounded = "all",
	disabled = false,
	height = "max-h-40",
}) => {
	return (
		<div className={`${className ? className : ""}`}>
			{label ? <Label htmlFor={name}>{label}</Label> : null}
			<Listbox value={state} onChange={setState}>
				{({ open }) => {
					return (
						<>
							<div className="relative">
								<Listbox.Button
									disabled={disabled}
									className={`${
										disabled
											? "pointer-events-none bg-gray-100 cursor-not-allowed text-gray-300"
											: "bg-white"
									} relative transition duration-100 w-full py-2 pl-3 pr-10 text-left ${
										rounded === "all"
											? "rounded-lg"
											: "rounded-lg rounded-r-none"
									} border border-gray-300 cursor-pointer hover:ring-2 hover:ring-primary-400 hover:ring-offset-4 hover:ring-offset-white focus:outline-none focus:ring-2 focus:ring-opacity-75 focus:ring-white focus:ring-offset-primary-400 focus:ring-offset-2 focus:border-transparent sm:text-sm`}
								>
									<span className="block truncate">
										{state}
									</span>
									<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
										<SelectorIcon
											className={`${
												disabled
													? "text-gray-500"
													: "text-primary-500"
											} w-5 h-5 `}
										/>
									</span>
								</Listbox.Button>
								<Transition
									show={open}
									as={Fragment}
									leave="transition ease-in duration-100"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<Listbox.Options
										static
										className={`absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${height}`}
									>
										{options.map((element, i) => {
											return (
												<Listbox.Option
													key={`${element}-${i}`}
													className={({ active }) => {
														return `${
															active
																? "text-primary-900 bg-primary-100"
																: "text-gray-900"
														} cursor-default select-none relative py-2 ${
															check
																? "pl-10 pr-4"
																: "text-center"
														}`;
													}}
													value={element}
												>
													{({ selected, active }) => {
														return (
															<>
																<span
																	className={`${
																		selected
																			? "font-bold"
																			: "font-normal"
																	} block truncate`}
																>
																	{element}
																</span>
																{selected ? (
																	<span
																		className={`${
																			active
																				? "text-primary-600"
																				: "text-primary-600"
																		}
                            absolute inset-y-0 left-0 flex items-center pl-3`}
																	>
																		{check ? (
																			<CheckIcon className="w-5 h-5" />
																		) : null}
																	</span>
																) : null}
															</>
														);
													}}
												</Listbox.Option>
											);
										})}
									</Listbox.Options>
								</Transition>
							</div>
						</>
					);
				}}
			</Listbox>
		</div>
	);
};

export default Select;
