import { Provider as ReservationProvider } from "@context/reservationContext";
import { Provider as PromotionContext } from "@context/promotionContext";
import { Provider as LocalizationProvider } from "@context/localizationContext";
import { Provider as SignupProvider } from "@context/signupContext";
import { Provider as CategoryProvider } from "@context/categoryContext";
import { Provider as RestaurantContext } from "@context/restaurantContext";

import Router from "@router";

const App = () => {
	return (
		<RestaurantContext>
			<LocalizationProvider>
				<ReservationProvider>
					<PromotionContext>
						<SignupProvider>
							<CategoryProvider>
								<Router />
							</CategoryProvider>
						</SignupProvider>
					</PromotionContext>
				</ReservationProvider>
			</LocalizationProvider>
		</RestaurantContext>
	);
};

export default App;
