import { useContext } from "react";

import { Context as LocalizationContext } from "@context/localizationContext";

const useLocalization = () => {
	const { state: localization, changeLocale } =
		useContext(LocalizationContext);

	return [
		localization,
		{
			changeLocale,
		},
	];
};

export default useLocalization;
