import { useNavigate } from "react-router-dom";

import Button from "@atoms/Button";

import Logo from "@logo";

const NotFound = () => {
	const navigate = useNavigate();
	return (
		<div className="flex items-center justify-around max-w-6xl px-4 py-12 mx-auto mt-16 bg-white rounded-md shadow-md ">
			<div className="flex flex-col items-start justify-left">
				<h1 className="text-4xl font-bold text-primary-800">
					404 - Página no encontrada!
				</h1>
				<p className="max-w-2xl mt-8 text-xl text-gray-600">
					Por favor verifica que el link sea el correcto. En Última
					Mesa recomendamos no pegar links directamente en navegador,
					ya que puede causar errores. Es mejor navegar usando las
					diferentes páginas y comandos.
				</p>
				<Button
					className="self-center mt-12"
					text="Regresar"
					onClick={() => {
						navigate("/", { replace: true });
					}}
				/>
			</div>
			<Logo className="w-32 h-32 animate-loader" />
		</div>
	);
};

export default NotFound;
