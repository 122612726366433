import {
	storage,
	ref,
	_TaskState,
	getDownloadURL,
	uploadBytesResumable,
} from "@utils/firebase";

const uploadFileGetLink = async (
	restaurantId,
	file,
	bucket,
	name,
	setProgress = false,
) => {
	const fileRef = ref(
		storage,
		`restaurants/${restaurantId}/${bucket}/${name ? name : file.name}`,
	);

	return new Promise((resolve, reject) => {
		const uploadTask = uploadBytesResumable(fileRef, file);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100,
				);
				setProgress && setProgress(progress);

				console.log(`Upload is ${progress}% done`);
				switch (snapshot.state) {
					case _TaskState.PAUSED:
						console.log("Upload is paused");
						break;
					case _TaskState.RUNNING:
						console.log("Upload is running");
						break;
				}
			},
			(error) => {
				console.log(error);
				return reject(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
					console.log("File available at", downloadUrl);
					return resolve(downloadUrl);
				});
			},
		);
	});
};

export default uploadFileGetLink;
