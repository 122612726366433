export const request = (placeId) => {
	return {
		placeId,
		fields: [
			"name",
			"geometry",
			"business_status",
			"formatted_phone_number",
			"address_components",
			"formatted_address",
			"rating",
			"price_level",
			"opening_hours",
			"types",
			"photos",
			"website",
			"user_ratings_total",
			"formatted_phone_number",
		],
	};
};

export const options = {
	location: new google.maps.LatLng(19.4326, -99.1332),
	radius: 3000,
	componentRestrictions: { country: "mx" },
};
