const Card = ({ children, className }) => {
	return (
		<section
			className={`bg-white overflow-hidden shadow rounded-lg ${
				className ? className : ""
			}`}
		>
			{children}
		</section>
	);
};

export default Card;
