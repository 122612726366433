import { useContext, useEffect } from "react";

import { Context as RestaurantContext } from "@context/restaurantContext";

import { useAuth } from "@hooks/useAuth";

const useRestaurant = () => {
	const auth = useAuth();
	const {
		state: { data: restaurant, isLoaded },
		getRestaurant,
		updateAmenities,
		updateSchedule,
		updateAddress,
		addImage,
		updateLogo,
		changeImageOrder,
		deleteImage,
		addDocument,
		deleteDocument,
		resetRestaurantContext,
	} = useContext(RestaurantContext);

	useEffect(() => {
		if (!isLoaded && auth) {
			getRestaurant(auth.user.uid);
		}
	});

	useEffect(() => {
		console.log("restaurant:", restaurant);
	}, [restaurant]);

	return [
		restaurant,
		{
			updateAmenities,
			updateSchedule,
			updateAddress,
			addImage,
			updateLogo,
			changeImageOrder,
			deleteImage,
			addDocument,
			deleteDocument,
			resetRestaurantContext,
		},
		isLoaded,
	];
};

export default useRestaurant;
