import { lazy } from "react";

import withFallback from "@HOC/withFallback";

import useRestaurant from "@hooks/useRestaurant";

import hasOwnProperty from "@utils/hasOwnProperty";

import Template from "@templates/Content";

const AmenitiesCheckboxes = withFallback(
	lazy(() => {
		return import("@organisms/AmenitiesUpdate");
	}),
);

const Amenities = () => {
	const [restaurant] = useRestaurant();

	return (
		<Template
			title="Amenidades del restaurante"
			description="Agrega las amenidades de tu restaurante. Esto ayudará a que tus comensales escojan tu restaurante basado en los diversos servicios que ofrece."
		>
			<AmenitiesCheckboxes
				initialValues={
					hasOwnProperty(restaurant, "amenities")
						? restaurant.amenities
						: false
				}
			/>
		</Template>
	);
};

export default Amenities;
