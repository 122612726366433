import Select from "@atoms/Select";

const TimePicker = ({ state, setState, disabled = false, timeSlots }) => {
	return (
		<span className="relative flex flex-wrap justify-center my-2 gap-x-2">
			<Select
				options={timeSlots}
				state={state}
				setState={setState}
				check={false}
				disabled={disabled}
				height="max-h-32"
			/>
		</span>
	);
};

export default TimePicker;
