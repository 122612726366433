import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useRestaurant from "@hooks/useRestaurant";

import PaddedContent from "@templates/PaddedContent";

import SuccessModal from "@modals/SuccessModal";

import ScheduleContent from "@organisms/Schedule";

import SectionHeader from "@molecules/SectionHeader";

const Schedule = () => {
	const navigate = useNavigate();
	const [showModal, toggleModal] = useState(false);
	const [restaurant, _, isLoaded] = useRestaurant();

	return isLoaded ? (
		<>
			<SuccessModal
				showModal={showModal}
				toggleModal={toggleModal}
				activeAction={() => {
					return navigate("/");
				}}
				title="Cambio de horario de restaurante"
				description="El cambio del horario se realizó con éxito. Por favor revisa la aplicación para verificar que sean correctos"
				activeButtonText="Continuar"
			/>
			<PaddedContent>
				<div className="max-w-2xl p-4 mx-auto mt-4 bg-white rounded-md">
					<SectionHeader
						title="Horario del restaurante"
						body="Valida el horario de tu restaurante para que aparezca de forma correcta en la aplicación móvil. Por favor elige si el horario es el mismo en toda la semana o si el restaurante cuenta con horarios de apertura y cierre diferentes en distintos días."
					/>
					<ScheduleContent
						toggleModal={toggleModal}
						restaurant={restaurant}
					/>
				</div>
			</PaddedContent>
		</>
	) : null;
};

export default Schedule;
