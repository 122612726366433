import { useState, useEffect } from "react";
import {
	ChevronDoubleLeftIcon,
	ChevronDoubleRightIcon,
} from "@heroicons/react/outline";
import {
	eachDayOfInterval,
	format,
	startOfMonth,
	endOfMonth,
	addMonths,
	getYear,
	subMonths,
	getDay,
	addDays,
	isSameDay,
	fromUnixTime,
} from "date-fns";
import { es } from "date-fns/esm/locale";

import daysInWeek from "@constants/daysInWeek";

const Calendar = () => {
	const events = {
		firstMonth: [
			{
				title: "Happy Hour",
				date: fromUnixTime(1622049914),
				timeStart: "11:00",
				timeEnd: "15:00",
			},
			{
				title: "Happy Hour",
				date: new Date(),
				timeStart: "12:00",
				timeEnd: "15:00",
			},
			{
				title: "Happy Hour",
				date: addDays(new Date(), 1),
				timeStart: "12:00",
				timeEnd: "15:00",
			},
			{
				title: "Happy Hour",
				date: addDays(new Date(), 2),
				timeStart: "12:00",
				timeEnd: "15:00",
			},
			{
				title: "Happy Hour",
				date: addDays(new Date(), 3),
				timeStart: "12:00",
				timeEnd: "15:00",
			},
		],
	};

	const [currentMonth, setCurrentMonth] = useState(startOfMonth(new Date()));
	const [days, setDays] = useState(
		eachDayOfInterval({
			start: startOfMonth(currentMonth),
			end: endOfMonth(startOfMonth(currentMonth)),
		}),
	);

	useEffect(() => {
		setDays(
			eachDayOfInterval({
				start: startOfMonth(currentMonth),
				end: endOfMonth(startOfMonth(currentMonth)),
			}),
		);
	}, [currentMonth]);

	const MonthlyNav = () => {
		return (
			<div className="flex justify-end mb-4">
				<button
					onClick={() => {
						return setCurrentMonth(subMonths(currentMonth, 1));
					}}
					className="cursor-pointer"
				>
					<ChevronDoubleLeftIcon className="w-6 h-6 text-gray-400 transition duration-75 hover:text-gray-600" />
				</button>
				<div
					className="w-32 ml-4 mr-4 font-bold text-center capitalize"
					aria-label="Current Month"
				>
					{format(
						currentMonth,
						getYear(currentMonth) === getYear(new Date())
							? "MMMM"
							: "MMMM yyyy",
						{ locale: es },
					)}
				</div>
				<button
					onClick={() => {
						return setCurrentMonth(addMonths(currentMonth, 1));
					}}
					className="cursor-pointer"
				>
					<ChevronDoubleRightIcon className="w-6 h-6 text-gray-400 transition duration-75 hover:text-gray-600" />
				</button>
			</div>
		);
	};

	const handleOmittedDays = ({ days, omitDays }) => {
		let headings = daysInWeek;
		let daysToRender = days;

		if (omitDays) {
			headings = daysInWeek.filter((day) => {
				return !omitDays.includes(day.day);
			});
			daysToRender = days.filter((day) => {
				return !omitDays.includes(getDay(day));
			});
		}

		let firstDayOfMonth = getDay(daysToRender[0]);
		if (omitDays) {
			const subtractOmittedDays = omitDays.filter((day) => {
				return day < firstDayOfMonth;
			}).length;
			firstDayOfMonth = firstDayOfMonth - subtractOmittedDays;
		}
		const padding = new Array(firstDayOfMonth).fill(0);

		return { headings, daysToRender, padding };
	};

	const MonthlyBody = ({ omitDays, events, renderDay }) => {
		const { headings, daysToRender, padding } = handleOmittedDays({
			days,
			omitDays,
		});

		const DayLabels = () => {
			return headings.map((day) => {
				return (
					<div
						key={day.day}
						className="hidden p-2 border-b-2 border-r-2 lg:block bg-primary-800 text-primary-50"
						aria-label="Day of Week"
					>
						{day.label}
					</div>
				);
			});
		};

		const DayPadding = () => {
			return padding.map((_, index) => {
				return (
					<div
						key={index}
						className="hidden p-2 border-b-2 border-r-2 lg:block"
						aria-label="Empty Day"
					/>
				);
			});
		};

		const DayRender = () => {
			return daysToRender.map((day) => {
				const dayData = events.filter((data) => {
					return isSameDay(data.date, day);
				});
				const dayNumber = format(day, "d");
				return (
					<div
						key={day.toISOString()}
						aria-label={`Events for day ${dayNumber}`}
						className="h-48 border-b-2 border-r-2"
					>
						<div className="flex justify-between pt-2 pl-2">
							<div className="font-bold text-primary-800">
								{dayNumber}
							</div>
							<div className="block lg:hidden">
								{format(day, "EEEE")}
							</div>
						</div>
						<ul
							className="overflow-hidden overflow-y-auto divide-y divide-gray-200 max-h-36"
							style={{}}
						>
							{renderDay(dayData)}
						</ul>
					</div>
				);
			});
		};

		return (
			<div className="bg-white border-t-2 border-l-2">
				<div
					className={
						"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-7 xl:grid-cols-7 border"
					}
				>
					<DayLabels />
					<DayPadding />
					<DayRender />
				</div>
			</div>
		);
	};

	return (
		<div className="mx-4 mt-4">
			<MonthlyNav />
			<MonthlyBody
				events={events.firstMonth}
				renderDay={(data) => {
					return data.map((item, index) => {
						return (
							<li
								className={`py-2 px-2 ${
									index % 2 === 0
										? "bg-primary-50"
										: "bg-primary-200"
								} `}
								key={index}
							>
								<div className="flex justify-between flex-1 text-sm">
									<h3 className="font-medium">
										{item.title}
									</h3>
									<p className="text-gray-500">{`${item.timeStart} - ${item.timeEnd}`}</p>
								</div>
							</li>
						);
					});
				}}
			/>
		</div>
	);
};

export default Calendar;
