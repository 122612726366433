import { lazy } from "react";

import withFallback from "@HOC/withFallback";

const PasswordSettings = withFallback(
	lazy(() => {
		return import("@organisms/PasswordUpdate");
	}),
);

const Settings = () => {
	return <PasswordSettings />;
};

export default Settings;
