import { Fragment, forwardRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

const { Root, Child } = Transition;
const { Overlay } = Dialog;

const Modal = forwardRef(({ isOpen, onClose, children }, ref) => {
	return (
		<Root show={isOpen} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed inset-0 z-10 overflow-y-auto"
				initialFocus={ref}
				open={isOpen}
				onClose={onClose}
			>
				<div className="flex items-center justify-center min-h-screen text-center sm:block sm:p-0">
					<Child
						as={Fragment}
						enter="ease-out transition duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
					</Child>

					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Child
						as={Fragment}
						enter="ease-out transition transform duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in transition transform duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						{children}
					</Child>
				</div>
			</Dialog>
		</Root>
	);
});

Modal.displayName = "Modal";

export default Modal;
