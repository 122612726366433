import { lazy } from "react";

import withFallback from "@HOC/withFallback";

const VerificationList = withFallback(
	lazy(() => {
		return import("@organisms/Verification");
	}),
);

const Verification = () => {
	return <VerificationList />;
};

export default Verification;
