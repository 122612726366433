export const SectionDescription = ({ text }) => {
	return (
		<p className="mt-2 text-sm text-justify text-gray-500 md:text-md">
			{text}
		</p>
	);
};

export const SectionTitle = ({ text }) => {
	return (
		<h3 className="font-medium leading-6 text-gray-900 text-md md:text-xl">
			{text}
		</h3>
	);
};
