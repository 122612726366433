const Logo = ({ className, color, onClick, mobile = false }) => {
	return (
		<svg
			onClick={onClick}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 100 100"
			className={className}
		>
			<defs>
				<linearGradient
					id="linear-gradient"
					y1="50"
					x2="75"
					y2="50"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#6e1c87" />
					<stop offset="1" stopColor="#ad2489" />
				</linearGradient>
			</defs>

			<path
				fill={`${
					mobile ? "#6e1c87" : color ? color : "url(#linear-gradient)"
				}`}
				d="M11.26,41.4H5.36A5.46,5.46,0,0,0,0,47v6.1A5.46,5.46,0,0,0,5.36,58.6h5.9a5.46,5.46,0,0,0,5.36-5.55V47A5.46,5.46,0,0,0,11.26,41.4ZM68.91,24.12H27.8a4.55,4.55,0,0,0-4.47,4.62V71.26a4.55,4.55,0,0,0,4.47,4.62H68.91a4.55,4.55,0,0,0,4.47-4.62V28.74A4.55,4.55,0,0,0,68.91,24.12Zm-6,36.23A4.55,4.55,0,0,1,58.4,65h-20a4.55,4.55,0,0,1-4.47-4.62V39.65A4.55,4.55,0,0,1,38.38,35h20a4.55,4.55,0,0,1,4.47,4.62ZM51.3,82.81H45.41A5.45,5.45,0,0,0,40,88.35v6.1A5.46,5.46,0,0,0,45.41,100H51.3a5.46,5.46,0,0,0,5.37-5.55v-6.1A5.45,5.45,0,0,0,51.3,82.81ZM51.3,0H45.41A5.46,5.46,0,0,0,40,5.55v6.1a5.45,5.45,0,0,0,5.37,5.54H51.3a5.45,5.45,0,0,0,5.37-5.54V5.55A5.46,5.46,0,0,0,51.3,0Z"
			/>
			<path
				fill="#e0b246"
				d="M98.69,53.27l-5.43,5.62a4.37,4.37,0,0,1-6.32,0L81.5,53.27a4.75,4.75,0,0,1,0-6.54l5.44-5.62a4.37,4.37,0,0,1,6.32,0l5.43,5.62A4.73,4.73,0,0,1,98.69,53.27Z"
			/>
		</svg>
	);
};

export default Logo;
