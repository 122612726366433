import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "@hooks/useAuth";

import Loader from "@atoms/Loading";

const PrivateRoute = ({ children }) => {
	const auth = useAuth();
	const location = useLocation();

	return !auth ? (
		<Loader screen />
	) : auth.user ? (
		children
	) : (
		<Navigate to={"/signin"} state={{ from: location }} replace />
	);
};
export default PrivateRoute;
