import { useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

import useOnClickOutside from "@hooks/useOnClickOutside";

import navigation from "@constants/Navigation";

import ScreenReader from "@atoms/ScreenReader";

import LogoIcon from "@logo";

const Sidebar = ({ show, toggle }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const sidebarRef = useRef(null);
	const [infoLinks, otherLinks] = navigation();
	useOnClickOutside(sidebarRef, () => {
		return toggle(false);
	});

	const Desktop = () => {
		return (
			<section className="hidden lg:flex lg:flex-shrink-0">
				<div className="flex flex-col w-52">
					<div className="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto bg-primary-600">
						<Logo />
						<nav
							className="flex flex-col flex-1 mt-5 overflow-y-auto divide-y divide-primary-700"
							aria-label="Sidebar"
						>
							<InfoLinks />
							<OtherLinks />
						</nav>
					</div>
				</div>
			</section>
		);
	};

	const InfoLinks = () => {
		return (
			<section className="px-2 space-y-1">
				{infoLinks.map((body, i) => {
					return (
						<Link
							key={i}
							to={body.route}
							onClick={() => {
								return toggle(false);
							}}
							className={`${
								location.pathname === body.route ||
								(location.pathname.includes(body.route) &&
									body.route !== "/")
									? "bg-primary-700 text-white"
									: "text-primary-100 hover:text-white hover:bg-primary-700"
							} group flex items-center px-2 py-2 text-base font-medium rounded-md ${
								body.futureFeature
									? "bg-primary-50 text-primary-900 pointer-events-none opacity-30"
									: ""
							}`}
							aria-current="page"
						>
							<body.icon
								className={`mr-4 h-6 w-6 ${
									body.futureFeature
										? "text-primary-900"
										: "text-primary-100"
								}`}
							/>
							{body.name}
						</Link>
					);
				})}
			</section>
		);
	};

	const OtherLinks = () => {
		return (
			<section className="pt-6 mt-6">
				<div className="px-2 space-y-1">
					{otherLinks.map((body, i) => {
						return (
							<Link
								key={i}
								to={body.route}
								onClick={() => {
									return toggle(false);
								}}
								className={`${
									location.pathname === body.route
										? "bg-primary-700 text-white"
										: "text-primary-100 hover:text-white hover:bg-primary-700"
								} group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md`}
							>
								<body.icon className="w-6 h-6 mr-4 text-primary-200" />
								{body.name}
							</Link>
						);
					})}
				</div>
			</section>
		);
	};

	const Logo = ({ mobile = false }) => {
		return (
			<div className="flex items-center justify-center flex-shrink-0 px-4">
				<LogoIcon
					className="w-auto p-1 bg-white rounded-full shadow-lg cursor-pointer h-28"
					onClick={() => {
						navigate("/");
						toggle(false);
					}}
					mobile={mobile}
				/>
			</div>
		);
	};

	const Mobile = () => {
		return (
			<section
				ref={sidebarRef}
				className="relative flex flex-col flex-1 w-full h-full max-w-2xl pt-5 pb-4 pr-10 bg-primary-700"
			>
				<div className="absolute top-0 right-0 pt-2 -mr-12">
					<button
						onClick={() => {
							return toggle(false);
						}}
						className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
					>
						<ScreenReader dictation="Close Sidebar" />
						<XIcon className="w-6 h-6 text-white" />
					</button>
				</div>
				<Logo mobile />
				<nav
					className="flex-shrink-0 h-full mt-5 overflow-y-auto divide-y divide-primary-800"
					aria-label="Sidebar"
				>
					<InfoLinks />
					<OtherLinks />
				</nav>
			</section>
		);
	};

	return (
		<>
			<Desktop />
			<Transition show={show}>
				<div className="lg:hidden">
					<div className="fixed inset-0 z-40 flex">
						<Transition.Child
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0">
								<div
									className="absolute inset-0 bg-gray-600 opacity-75"
									aria-hidden="true"
								></div>
							</div>
						</Transition.Child>

						<Transition.Child
							enter="transition ease-in-out duration-300 transform"
							enterFrom="-translate-x-full"
							enterTo="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-x-0"
							leaveTo="-translate-x-full"
						>
							<Mobile />
						</Transition.Child>
						<div
							className="flex-shrink-0 w-14"
							aria-hidden="true"
						></div>
					</div>
				</div>
			</Transition>
		</>
	);
};

export default Sidebar;
