import { useState, useEffect, lazy } from "react";
import { useParams } from "react-router-dom";

import withFallback from "@HOC/withFallback";

import usePromotions from "@hooks/usePromotions";

import NotFound from "@atoms/NotFound";
import Loader from "@atoms/Loading";

const PromotionInfo = withFallback(
	lazy(() => {
		return import("@organisms/PromotionInfo");
	}),
);

const Promotion = () => {
	const { id } = useParams();
	const [_, { filterPromotion }, isLoaded] = usePromotions();
	const [currentPromotion, setCurrentPromotion] = useState(
		filterPromotion(id) || null,
	);

	useEffect(() => {
		if (isLoaded) {
			setCurrentPromotion(filterPromotion(id));
		}
	}, [filterPromotion, id, isLoaded]);

	return isLoaded ? (
		currentPromotion ? (
			<PromotionInfo promotion={currentPromotion} />
		) : (
			<NotFound
				route="/promotions"
				text="No se encontró ninguna promoción con ese ID"
			/>
		)
	) : (
		<Loader />
	);
};

export default Promotion;
