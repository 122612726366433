import SectionHeader from "@molecules/SectionHeader";

const Content = ({ title, description, children }) => {
	return (
		<header className="max-w-2xl p-4 mx-auto mt-10 bg-white divide-y divide-gray-200 rounded-md shadow-md">
			<div className="space-y-1">
				<SectionHeader title={title} body={description} />
			</div>
			<div className="pt-4 mt-6">{children}</div>
		</header>
	);
};

export default Content;
