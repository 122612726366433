import { SectionDescription, SectionTitle } from "@atoms/SectionElements";

const SectionHeader = ({ title, body }) => {
	return (
		<>
			<SectionTitle text={title} />
			<SectionDescription text={body} />
		</>
	);
};

export default SectionHeader;
