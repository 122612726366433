import { useReducer } from "react";
import { useAuth } from "@useAuth";
import useRestaurant from "@hooks/useRestaurant";

import Button from "@atoms/Button";

import SchedulePicker from "@molecules/SchedulePicker";

const reducer = (state, action) => {
	switch (action.type) {
		case "toggle_day":
			return [
				...state.map((_, i) => {
					return i === action.payload.index
						? { ...state[i], status: action.payload.status }
						: state[i];
				}),
			];
		case "change_open_time":
			return [
				...state.map((_, i) => {
					return i === action.payload.index
						? { ...state[i], open: action.payload.open }
						: state[i];
				}),
			];
		case "change_close_time":
			return [
				...state.map((_, i) => {
					return i === action.payload.index
						? { ...state[i], close: action.payload.close }
						: state[i];
				}),
			];
		default:
			return state;
	}
};

const Schedule = ({ toggleModal, restaurant }) => {
	const auth = useAuth();
	const [_, { updateSchedule }] = useRestaurant();

	const [scheduleState, dispatch] = useReducer(
		reducer,
		restaurant.fullSchedule,
	);

	return (
		<div className="flex-row items-center justify-between">
			<SchedulePicker schedule={scheduleState} dispatch={dispatch} />
			<Button
				className="w-full mt-8"
				text="Guardar"
				onClick={() => {
					updateSchedule(auth.user.uid, scheduleState, () => {
						return toggleModal(true);
					});
				}}
			/>
		</div>
	);
};

export default Schedule;
