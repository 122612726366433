import { lazy } from "react";

import withFallback from "@HOC/withFallback";

const PasswordResetForm = withFallback(
	lazy(() => {
		return import("@forms/PasswordResetForm");
	}),
);
const Header = withFallback(
	lazy(() => {
		return import("@organisms/EntryHeader");
	}),
);

const PasswordReset = () => {
	return (
		<main>
			<Header type={"reset"} />
			<PasswordResetForm />
		</main>
	);
};

export default PasswordReset;
