import { lazy } from "react";

import withFallback from "@HOC/withFallback";

import useLocalization from "@hooks/useLocalization";

const SectionHeader = withFallback(
	lazy(() => {
		return import("@molecules/SectionHeader");
	}),
);
const Form = withFallback(
	lazy(() => {
		return import("@forms/NewPromotionForm");
	}),
);

const Promotion = () => {
	const [{ newPromotionForm: localization }] = useLocalization();

	return (
		<main className="max-w-2xl mx-auto mt-10">
			<div className="px-4 mb-6 sm:px-0">
				<SectionHeader
					title={localization.title}
					body={localization.description}
				/>
			</div>

			<Form className="px-4 py-5 bg-white shadow sm:p-6 sm:rounded-md" />
		</main>
	);
};

export default Promotion;
