import { lazy } from "react";

import withFallback from "@HOC/withFallback";

const DocumentUpdate = withFallback(
	lazy(() => {
		return import("@organisms/DocumentUpdate");
	}),
);

const Menus = () => {
	return (
		<main>
			<DocumentUpdate />
		</main>
	);
};

export default Menus;
