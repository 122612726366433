import { useEffect, lazy } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import withFallback from "@HOC/withFallback";

import { useAuth } from "@useAuth";

const SigninForm = withFallback(
	lazy(() => {
		return import("@forms/SigninForm");
	}),
);

const Header = withFallback(
	lazy(() => {
		return import("@organisms/EntryHeader");
	}),
);

const Signin = () => {
	const { REACT_APP_AUTH_KEY } = process.env;
	const navigate = useNavigate();
	const auth = useAuth();
	const location = useLocation();

	const { from } = location.state || { from: { pathname: "/" } };

	useEffect(() => {
		if (auth.user) {
			navigate(from, { replace: true });
		}
	}, [auth.user, navigate, from, REACT_APP_AUTH_KEY]);

	return (
		<main>
			<Header type={"signin"} />
			<SigninForm className="mt-8" />
		</main>
	);
};

export default Signin;
