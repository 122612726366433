import Switch from "@atoms/Switch";

const DayBubble = ({ status, dispatch, text, index }) => {
	return (
		<div className="flex flex-col gap-y-4">
			<div
				className={`h-14 w-14 rounded-full text-2xl font-semibold flex justify-center items-center ${
					status
						? "bg-primary-600 text-white"
						: "bg-gray-400 text-white"
				}`}
			>
				<div>{text}</div>
			</div>
			<div className="m-auto">
				<Switch
					disabledColor="bg-gray-400"
					enabled={status}
					customMethod={() => {
						dispatch({
							type: "toggle_day",
							payload: { status: !status, index },
						});
					}}
				/>
			</div>
		</div>
	);
};

export default DayBubble;
